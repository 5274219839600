import React from 'react'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { 
  Dialog,
  Button,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  FormGroup,
  FormControlLabel,
  Checkbox,
  Grid
} from '@material-ui/core'
import { connect } from 'react-redux'

import { 
  fetchRegister, 
  openModalRegister,
  fetchUsers,
  setAdminRegister,
  setAdmin
} from '../redux'

import DropdownFileSizeUnit from './DropdownFileSizeUnit'

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      width: '35ch',
    },
  },
  textFieldClass: {
    width: 60,
    marginTop: theme.spacing(2.5),
  }
}));


const RegisterForm = ({ response, fetchRegister, openModalRegister, fetchUsers, setAdminRegister }) => {
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = values => {
    fetchRegister(values)
  }

  const handleChangeAdmin = (event) =>{
    setAdminRegister(event.target.checked)
  }

  const classes = useStyles();

  return (
    <Dialog 
      open={response.modalRegisterIsOpen} 
      onClose={()=>{
        openModalRegister(false)
        setAdminRegister(false)
        fetchUsers()
      }}
      aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Register</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)} className={classes.root}>
        <DialogContent>
            <div>
              <div>
                <Typography variant="h9">
                  Email
                </Typography>
                <TextField fullWidth autoFocus margin="dense" name="email" type="email" placeholder="Email" inputRef={register} />
              </div>
              <br/>
              <div>
                <Typography variant="h9">
                  First Name
                </Typography>
                <TextField fullWidth autoFocus margin="dense" name="firstName" type="text" placeholder="First name" inputRef={register} />
              </div>
              <br />
              <div>
                <Typography variant="h9">
                  Last Name
                </Typography>
                <TextField fullWidth autoFocus margin="dense" name="lastName" type="text" placeholder="Last Name" inputRef={register} />
              </div>
              <br />
              <div>
                <Typography variant="h9">
                  Company Name
                </Typography>
                <TextField fullWidth autoFocus margin="dense" name="companyName" type="text" placeholder="Company Name" inputRef={register} />
              </div>
              <br />
              <div>
                <Typography variant="h9">
                  Storage Limit
                </Typography>
                <Grid container>
                  <Grid item  xs={3} >
                    <TextField className={classes.textFieldClass} autoFocus margin="dense" name="storageLimit" type="text" defaultValue="100" placeholder="Storage Limit" inputRef={register} />
                  </Grid>
                  <Grid item  xs={1} >
                    <DropdownFileSizeUnit />
                  </Grid>
                </Grid>
              </div>
              <br />
              <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={response.admin} onChange={handleChangeAdmin} name="admin" />}
                    label="admin"
                />
              </FormGroup>
            </div>
            <DialogContentText>
                {response.error.toString()}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button 
              onClick={()=>{
                openModalRegister(false)
                setAdminRegister(false)
                fetchUsers()
              }} 
              color="secondary">
                Cancel
            </Button>
            <Button color="primary" type="submit">
                Register
            </Button>
        </DialogActions>
      </form>
    </Dialog>
  )
}

const mapStateToProps = state => {
  return {
      response: state.register
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchRegister: (values) => dispatch(fetchRegister(values)),
    openModalRegister: (values) => dispatch(openModalRegister(values)),
    fetchUsers: (values) => dispatch(fetchUsers(values)),
    setAdminRegister: (values) => dispatch(setAdminRegister(values))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(RegisterForm)