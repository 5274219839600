import React from 'react'
import { connect } from 'react-redux'
import { 
    openDeleteUserDialog,
    deleteUser
} from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

const DeleteUserDialogForm = ({response, control, openDeleteUserDialog, deleteUser}) => {
    
  const { handleSubmit, register, errors } = useForm();
  const email = response.userInformation.email
  

  const onSubmit = values => deleteUser(email);

  

  return (
    <Dialog open={control.openDeleteUserDialog} onClose={()=>openDeleteUserDialog(false)} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Delete user</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete the user? This action cannot be reverted.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>openDeleteUserDialog(false)} color="secondary">
                Cancel
            </Button>
            <Button color="primary" type="submit">
                Delete
            </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

const mapStateToProps = state => {
    return {
        control: state.control,
        response: state.register
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDeleteUserDialog: (values) => dispatch(openDeleteUserDialog(values)),
        deleteUser: (values) => dispatch(deleteUser(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteUserDialogForm)