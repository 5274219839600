import React from 'react';
import { Provider } from 'react-redux';
import store from './redux/store';
import './App.css';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';


import LoginContainer from './components/LoginContainer'
import HomePageContainer from './components/HomePageContainer'
import UsersContainer from './components/UsersContainer'
import AccountContainer from './components/AccountContainer'
import FileContainer from './components/FileContainer'

function App() {
  return (
    <Provider store={store}>
      <Router>
        <div className="App">
          <Switch>
            <Route path="/" exact component={HomePageContainer} />
            <Route path="/storage/:currentPathFolder" component={FileContainer} />
            <Route path="/login" exact component={LoginContainer} />
            <Route path="/users" exact component={UsersContainer} />
            <Route path="/account" exact component={AccountContainer}/>
          </Switch>
        </div>
      </Router>
    </Provider>   
  );
}

export default App;
