export const OPEN_HOME_DRAWER = 'OPEN_HOME_DRAWER'
export const SET_NAVIGATION_TITLE = 'SET_NAVIGATION_TITLE'
export const TOGGLE_PROGRESS_BAR = 'TOGGLE_PROGRESS_BAR'
export const TOGGLE_ACCOUNT_OPTIONS = 'TOGGLE_ACCOUNT_OPTIONS'
export const OPEN_USER_INFORMATION = 'OPEN_USER_INFORMATION'
export const SET_PROGRESS_BAR_VISIBILITY = 'SET_PROGRESS_BAR_VISIBILITY'
export const OPEN_CHANGE_PASSWORD_FORM = 'OPEN_CHANGE_PASSWORD_FORM'
export const OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE = 'OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE'
export const OPEN_DELETE_FILE_DIALOG = 'OPEN_DELETE_FILE_DIALOG'
export const OPEN_DELETE_USER_DIALOG = 'OPEN_DELETE_USER_DIALOG'
export const OPEN_GENERATE_PASSWORD_DIALOG = 'OPEN_GENERATE_PASSWORD_DIALOG'
export const OPEN_CONFLICT_FILE_MODAL = 'OPEN_CONFLICT_FILE_MODAL'
export const OPEN_ALERT_FILE_DELETE_FAILURE = 'OPEN_ALERT_FILE_DELETE_FAILURE'
export const OPEN_FILE_INFORMATION_MODAL = 'OPEN_FILE_INFORMATION_MODAL'
export const OPEN_UPDATE_USER_INFORMATION_FORM = 'OPEN_UPDATE_USER_INFORMATION_FORM'
export const OPEN_FILE_SIZE_UNIT_DROPDOWN = 'OPEN_FILE_SIZE_UNIT_DROPDOWN'
export const SET_UNIT = 'SET_UNIT'
export const RETRY_TOKEN = 'RETRY_TOKEN'
export const RENEW_TOKEN_EXPIRED = 'RENEW_TOKEN_EXPIRED'
export const CONTROL_RESET = 'CONTROL_RESET'
export const SHOW_SEARCH_DROPDOWN = 'SHOW_SEARCH_DROPDOWN'
export const ALLOW_FILE_UPLOAD = 'ALLOW_FILE_UPLOAD'
export const ALLOW_FOLDER_UPLOAD = 'ALLOW_FOLDER_UPLOAD'