export const FETCH_REGISTER_REQUEST = 'FETCH_REGISTER_REQUEST'
export const FETCH_REGISTER_SUCCESS = 'FETCH_REGISTER_SUCCESS'
export const FETCH_REGISTER_FAILURE = 'FETCH_REGISTER_FAILURE'
export const FETCH_USERS_REQUEST = 'FETCH_USERS_REQUEST'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USERS_FAILURE = 'FETCH_USERS_FAILURE'
export const OPEN_MODAL_REGISTER = 'OPEN_MODAL_REGISTER'
export const SET_ADMIN_REGISTER = 'SET_ADMIN_REGISTER'
export const SET_USER_INFORMATION_DISPLAY = 'SET_USER_INFORMATION_DISPLAY'
export const CHANGE_PASSWORD_REQUEST = 'CHANGE_PASSWORD_REQUEST'
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS'
export const CHANGE_PASSWORD_FAILURE = 'CHANGE_PASSWORD_FAILURE'
export const OPEN_ALERT_CHANGE_PASSWORD_SUCCESS = 'OPEN_ALERT_CHANGE_PASSWORD_SUCCESS'
export const OPEN_ALERT_CHANGE_PASSWORD_FAILURE = 'OPEN_ALERT_CHANGE_PASSWORD_FAILURE'
export const OPEN_ALERT_ADD_USER_SUCCESS = 'OPEN_ALERT_ADD_USER_SUCCESS'
export const OPEN_ALERT_ADD_USER_FAILURE = 'OPEN_ALERT_ADD_USER_FAILURE'
export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'
export const OPEN_ALERT_DELETE_USER_SUCCESS = 'OPEN_ALERT_DELETE_USER_SUCCESS'
export const GENERATE_PASSWORD_REQUEST = 'GENERATE_PASSWORD_REQUEST'
export const GENERATE_PASSWORD_SUCCESS = 'GENERATE_PASSWORD_SUCCESS'
export const GENERATE_PASSWORD_FAILURE = 'GENERATE_PASSWORD_FAILURE'
export const UPDATE_USER_REQUEST = 'UPDATE_USER_REQUEST'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const UPDATE_USER_FAILURE = 'UPDATE_USER_FAILURE'
export const REGISTER_RESET = 'REGISTER_RESET'