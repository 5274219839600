import React from 'react'
import { useForm } from 'react-hook-form'
import TextField from '@material-ui/core/TextField'
import { Button, Paper } from '@material-ui/core'
import { connect } from 'react-redux'
import { fetchLogin } from '../redux'
import { ThemeProvider as MuiThemeProvider } from '@material-ui/core/styles';
import { makeStyles } from '@material-ui/core/styles';

/*
const textFieldStyles = makeStyles({
  underline: {
    "&::before": {
      borderBottom: "1px solid #90caf9"
    },
    "&:hover:not(.Mui-disabled):before": {
      borderBottom: "2px solid #90caf9"
    },
    "&::after": {
      borderBottom: "2px solid #90caf9"
    }
  },
  input: {
    "&:-webkit-autofill": {
      WebkitBoxShadow: "0 0 0 1000px black inset"
    }
  }
});*/


const useStyles = makeStyles(theme =>({
  paperClass: {
    padding: theme.spacing(3, 2),
    height: 300,
    width: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    marginLeft: "75px",
    marginTop: window.innerHeight/2 - 175,
    marginRight: "10px",
    marginBottom: "5px",
    verticalAlign: 'middle',
    backgroundColor: 'rgba(255,255,255,0.3)'
  },
  buttonClass: {
    marginTop: "75px",
    width: 275,
    color: 'rgba(255,204,102,1)',
    fontWeight: 600,
    fontSize: 16
  },
  textfieldClass: {
    marginTop: "20px",
    width: 275
  },
  errorLabel: {
    fontSize: 13,
    color: "red"
  }

}));

const LoginForm = ({response, fetchLogin}) => {
  const { handleSubmit, register, errors } = useForm()

  const onSubmit = values => fetchLogin(values)

  const classes = useStyles()

  //const textFieldClasses = textFieldStyles()

  return (
    <MuiThemeProvider>
      <Paper elevation={3} square className={classes.paperClass}>
        <form onSubmit={handleSubmit(onSubmit)}>
            <TextField className={classes.textfieldClass} autoFocus margin="dense" name="userEmail" type="text" placeholder="User Email" inputRef={register} />
            <TextField className={classes.textfieldClass} autoFocus margin="dense" name="password" type="password" placeholder="Password" inputRef={register} />
            <h2 className={classes.errorLabel}>{response.error}</h2>
            <Button className={classes.buttonClass} type="submit" color="primary">
              Login
            </Button>
        </form>
      </Paper>
    </MuiThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
      response: state.login
  }
}

const mapDispatchToProps = dispatch => {
  return {
    fetchLogin: (values) => dispatch(fetchLogin(values))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(LoginForm)