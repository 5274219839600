import React from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    VpnKey
} from '@material-ui/icons'
import {
    Grid,
    IconButton,
    Snackbar
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';
import Tooltip from '@material-ui/core/Tooltip'

import {
    openChangePasswordForm,
    openAlertChangePasswordFailure,
    openAlertChangePasswordSuccess
} from '../redux'
import HomePageMenuDrawer from './HomePageDrawerContainer'
import AppBarContainer from './AppBarContainer'
import ChangePasswordForm from './AccountChangePasswordModalContainer'

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    buttonLabel: {
      marginLeft: "5px"
    },
    buttonIcon: {
      justifySelf: "flex-end"
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    }
  }));


const UsersContainer = ({ register, openChangePasswordForm, openAlertChangePasswordFailure, openAlertChangePasswordSuccess }) => {

    const classes = useStyles();
    

    const firstName = localStorage.getItem('firstName')
    const lastName = localStorage.getItem('lastName')
    const companyName = localStorage.getItem('companyName')
    const email = localStorage.getItem('email')
    const isAdmin = localStorage.getItem('isAdmin')
    localStorage.setItem('setUserEmail',email)

    return (
        
        <div>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={register.openAlertChangePasswordSuccess} 
            autoHideDuration={6000} 
            onClose={()=>openAlertChangePasswordSuccess(false)}
            >
                <Alert onClose={()=>openAlertChangePasswordSuccess(false)} severity="success">
                    Password updated!
                </Alert>
            </Snackbar>
            <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={register.openAlertChangePasswordFailure} 
            autoHideDuration={6000} 
            onClose={()=>openAlertChangePasswordFailure(false)}
            >
                <Alert onClose={()=>openAlertChangePasswordFailure(false)} severity="error">
                    Issue encountered while updating password.
                </Alert>
            </Snackbar>
            <ChangePasswordForm />
            <HomePageMenuDrawer />
            <div>
                <AppBarContainer />
            </div>
            <br />
            <div>
                <Grid container spacing ={2}>
                    <Grid alignItems="flex-start" item xs>
                        <h2 id="simple-modal-title">{lastName}, {firstName}</h2>
                        <p id="simple-modal-description">
                            {companyName}
                        </p>
                        <p>{email}</p>
                        <p>{isAdmin==="true"?"Administrator":"Standard User"}</p>
                    </Grid>
                    <Grid item xs={7}></Grid>
                    <Grid alignItems="center" item xs={1}>
                        <Tooltip title="Change password">
                            <IconButton color="inherit">
                                <VpnKey fontSize="medium" onClick={()=>openChangePasswordForm(true)} />
                            </IconButton>
                        </Tooltip>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        control: state.control,
        register: state.register
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openChangePasswordForm: (values) => dispatch(openChangePasswordForm(values)),
        openAlertChangePasswordFailure: (value) => dispatch(openAlertChangePasswordFailure(value)),
        openAlertChangePasswordSuccess: (value) => dispatch(openAlertChangePasswordSuccess(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersContainer)