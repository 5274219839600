import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import { Menu } from '@material-ui/icons';
import { useHistory } from 'react-router-dom'

import { 
    setLoginState,
    openHomeDrawer,
    setNavigationTitle,
    logOut
} from '../redux'

import AccountOptions from './AppBarAccountOptions'

const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1
    },
    menuButton: {
        marginRight: theme.spacing(2)
    },
    title: {
        flexGrow: 1,
    }
}));

const AppBarContainer = ({response, control, setLoginState, openHomeDrawer, setNavigationTitle, logOut }) => {

    const classes = useStyles();
    const history = useHistory()

    const logOutOnClick = () => {
        setLoginState(false)
        setNavigationTitle('Home')
        localStorage.setItem('accessToken','')
        localStorage.setItem('loggedIn', false)
        localStorage.setItem('refreshToken','')
        logOut()
        history.push('/login')
    }

    useEffect(()=>{
        if(response.loggedIn===false || localStorage.getItem('refreshToken')==='' || localStorage.getItem('refreshToken')===null){
            
            history.push('/login')
        }
        
    }, [])

    useEffect(()=>{
        if(control.renewTokenExpired){
            
            setLoginState(false)
            setNavigationTitle('Home')
            localStorage.setItem('accessToken','')
            localStorage.setItem('loggedIn', false)
            localStorage.setItem('refreshToken','')
            logOut()
            history.push('/login')
        }
    }, [control.renewTokenExpired])

    //
    return (
        <div>
            <div className={classes.root}>
                <AppBar position="static">
                    <Toolbar>
                        <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu">
                            <Menu onClick={()=>openHomeDrawer(true)} />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {control.navigationTitle}
                        </Typography>
                        <AccountOptions />
                    </Toolbar>
                </AppBar>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        response: state.login,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoginState: (value) => dispatch(setLoginState(value)),
        openHomeDrawer: (value) => dispatch(openHomeDrawer(value)),
        setNavigationTitle: (value) => dispatch(setNavigationTitle(value)),
        logOut: () => dispatch(logOut())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AppBarContainer)