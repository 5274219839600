import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Person
} from '@material-ui/icons'
import {
    Grid,
    List,
    ListItem, 
    ListItemIcon,
    ListItemText,
    Box
} from '@material-ui/core'
import MuiAlert from '@material-ui/lab/Alert';

import { 
    fetchUsers,
    openModalRegister,
    openUserInformation,
    setUserInformationDisplay,
    openAlertAddUserFailure,
    openAlertAddUserSuccess,
    openAlertDeleteUserSuccess,
    openAlertChangePasswordFailure,
    openAlertChangePasswordSuccess,
    setPermissionDefaultEmail,
    showSearchDropdown
} from '../redux'



const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
    root: {
      flexGrow: 1
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
      width: "100%"
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    buttonLabel: {
      marginLeft: "5px"
    },
    buttonIcon: {
      justifySelf: "flex-end"
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    }
  }));


const DropDownUsersContainer = ({showSearchDropdown, response , setPermissionDefaultEmail, fetchUsers, openModalRegister, openUserInformation, setUserInformationDisplay, openAlertAddUserFailure, openAlertAddUserSuccess, openAlertDeleteUserSuccess, openAlertChangePasswordFailure, openAlertChangePasswordSuccess }) => {

    const classes = useStyles();

    /*useEffect(()=>{
        fetchUsers()
    }, [])*/
    

    return (
            <Box position="absolute" width="100%">
                <Grid width="100%" container justify="center" spacing={2}>
                    <Grid width="100%" item xs={12} md={10}>
                        <div className={classes.demo}>
                            <List width="100%" dense={false}>
                                {response.usersResponse.map(user=>
                                    <ListItem width="100%" button onClick={()=>{
                                        //openUserInformation(true)
                                        //setUserInformationDisplay(user)
                                        console.log('clicked')
                                        setPermissionDefaultEmail(user.email)
                                        showSearchDropdown(false)
                                        }} divider>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${user.lastName}, ${user.firstName}`}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </Box>
    )
}

const mapStateToProps = state => {
    return {
        response: state.register
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUsers: () => dispatch(fetchUsers()),
        openModalRegister: (values) => dispatch(openModalRegister(values)),
        openUserInformation: (values) => dispatch(openUserInformation(values)),
        setUserInformationDisplay: (values) => dispatch(setUserInformationDisplay(values)),
        openAlertAddUserFailure: (value) => dispatch(openAlertAddUserFailure(value)),
        openAlertAddUserSuccess: (value) => dispatch(openAlertAddUserSuccess(value)),
        openAlertDeleteUserSuccess: (value) => dispatch(openAlertDeleteUserSuccess(value)),
        openAlertChangePasswordFailure: (value) => dispatch(openAlertChangePasswordFailure(value)),
        openAlertChangePasswordSuccess: (value) => dispatch(openAlertChangePasswordSuccess(value)),
        setPermissionDefaultEmail: (value) => dispatch(setPermissionDefaultEmail(value)),
        showSearchDropdown: (value) => dispatch(showSearchDropdown(value)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DropDownUsersContainer)