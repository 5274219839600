import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { size, toArray } from 'lodash'
import { Paper } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';

import UploadItem from '../UploadItem/UploadItem'
import UploadItemDone from '../UploadItem/UploadItemDone'
import UploadItemCancelled from '../UploadItem/UploadItemCancelled'
import UploadItemAbortInProgress from '../UploadItem/UploadItemAbortInProgress'
import Styles from '../css/UploadProgress.module.css'
import { uploadFile } from '../redux'


const useStyles = makeStyles(theme =>({
  paperClass: {
    //padding: theme.spacing(3, 2),
    display: "flex",
    flexDirection: "column",
    //justifyContent: "center",
    marginLeft: "5px",
    marginTop: "5px",
    marginRight: "10px",
    marginBottom: "5px",
    //verticalAlign: 'middle'
  }
}));

const UploadProgress = props => {

    const classes = useStyles()
    const { fileProgress, uploadFile } = props
    const uploadedFileAmount = size(toArray(fileProgress).filter(file=>(file.status===0)))
    //const uploadedFileAmount = size(fileProgress)
    const countFiles = size(fileProgress)
    const fileProgressArray = toArray(fileProgress).filter(file=>file.status!=5)
    

    useEffect(()=>{
        const fileToUpload = toArray(fileProgress).filter(file=>file.status===0)
        console.log('<<<<<<<  Triggering upload again >>>>>>>>>>>>>')
        console.log(fileToUpload)
        uploadFile(fileToUpload)
    }, [uploadedFileAmount])

    return countFiles > 0 ? (
        
        <div className={Styles.wrapper}>
            <Paper elevation={3} square className={classes.paperClass}>
            {size(fileProgressArray)
                ? toArray(fileProgressArray).map(file=>(
                    file.status===1?
                    <UploadItemDone key={file.id} id={file.id} file={file}/>
                    :file.status===4?
                    <UploadItemAbortInProgress key={file.id} id={file.id} file={file} />
                    :file.status===5?
                    //<UploadItemCancelled key={file.id} id={file.id} file={file} />
                    null
                    :<UploadItem key={file.id} id={file.id} file={file} />
                ))
                : null
            }
            </Paper>
        </div>
            
    ) : null
}

const mapDispatchToProps = dispatch => ({
    uploadFile: files => dispatch(uploadFile(files))
})

const mapStateToProps = state => ({
    fileProgress: state.file.fileProgress
})

export default connect(mapStateToProps, mapDispatchToProps)(UploadProgress)