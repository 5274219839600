import {
    FETCH_FILE_FAILURE,
    FETCH_FILE_REQUEST,
    FETCH_FILE_SUCCESS,
    STORE_FILE_UPLOAD,
    UPLOAD_FILE_REQUEST,
    UPLOAD_FILE_SUCCESS,
    UPLOAD_FILE_FAILURE,
    DELETE_FILE_FAILURE,
    DELETE_FILE_REQUEST,
    DELETE_FILE_SUCCESS,
    OPEN_MODAL_CREATE_FOLDER,
    SET_PERMISSION_FAILURE,
    SET_PERMISSION_REQUEST,
    SET_PERMISSION_SUCCESS,
    REFRESH_PERMISSION_ERROR,
    OPEN_MODAL_SET_PERMISSION,
    GET_PERMISSION_FAILURE,
    GET_PERMISSION_REQUEST,
    GET_PERMISSION_SUCCESS,
    OPEN_FORM_SET_PERMISSION,
    SET_READ,
    SET_DELETE,
    SET_WRITE,
    SET_ADMIN,
    SET_PERMISSION_DEFAULT_EMAIL,
    ADD_USER,
    SET_FILE_NAME,
    OPEN_MODAL_UPLOAD_FILE,
    CREATE_FOLDER_FAILURE,
    CREATE_FOLDER_SUCCESS,
    CREATE_FOLDER_REQUEST,
    SET_UPLOAD_FILE,
    SET_UPLOAD_PROGRESS,
    SUCCESS_UPLOAD_FILE,
    FAILURE_UPLOAD_FILE,
    SPEED_DIAL_OPEN,
    SET_UPLOAD_STATUS,
    SET_RESUME_PARAMS,
    SET_UPLOAD_ID,
    SET_CURRENT_UPLOAD_SIZE,
    SET_CONFLICT_FILES,
    SET_APPLY_TO_ALL,
    GET_FILE_INFORMATION_FAILURE,
    GET_FILE_INFORMATION_REQUEST,
    GET_FILE_INFORMATION_SUCCESS,
    GET_OWNER_FILES_FAILURE,
    GET_OWNER_FILES_REQUEST,
    GET_OWNER_FILES_SUCCESS,
    DELETE_PERMISSION_FAILURE,
    DELETE_PERMISSION_SUCCESS,
    DELETE_PERMISSION_REQUEST,
    ABORT_UPLOAD_FAILURE,
    ABORT_UPLOAD_REQUEST,
    ABORT_UPLOAD_SUCCESS,
    PART_UPLOAD_COMPLETE,
    FILE_RESET,
    CHECK_FOLDER_EXIST
} from './fileTypes'
import { modifyFiles } from '../../utils/uploadFile.utils'


const initialState = {
    fileProgress: {},
    loading: false,
    createFolderInProgress: false,
    createFolderStatus: '',
    createFolderError: '',
    getSinglePermissionInProgress: false,
    getSinglePermissionStatus: '',
    getSinglePermissionError: '',
    getPermissionInProgress: false,
    getPermissionStatus: [],
    getPermissionError: '',
    setPermissionInProgress: false,
    setPermissionStatus: '',
    setPermissionError: '',
    deleteInProgress: false,
    deleteStatus: '',
    deleteError: '',
    uploadInProgress: false,
    uploadStatus: '',
    uploadError: '',
    response: [],
    error: '',
    path: '',
    file: '',
    modalCreateFolderIsOpen: false,
    modalSetPermissionIsOpen: false,
    formSetPermissionIsOpen: false,
    read: false,
    write: false,
    delete: false,
    admin: false,
    permissionDefaultEmail: '',
    addUser: true,
    fileName: '',
    modalUploadFileIsOpen: false,
    speedDialIsOpen: false,
    conflictFiles: [],
    applyToAll: false,
    getFileInformationInProgress: false,
    getFileInformationData: {},
    getFileInformationError: '',
    getOwnerFilesError: '',
    getOwnerFilesData: '',
    getOwnerFilesInProgress: false,
    deletePermissionError: '',
    deletePermissionSuccess: '',
    deletePermissionInProgress: false,
    abortUploadError: '',
    abortUploadSuccess: '',
    abortUploadInProgress: false,
    folderExists: 0,
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case CHECK_FOLDER_EXIST:
            return {
                ...state,
                folderExists: action.payload,
            }
        case FILE_RESET:
            return {
                ...state,
                ...initialState
            }
        case ABORT_UPLOAD_FAILURE:
            return {
                ...state,
                abortUploadError: action.payload,
                abortUploadSuccess: '',
                abortUploadInProgress: false
            }
        case ABORT_UPLOAD_REQUEST:
            return {
                ...state,
                abortUploadError: '',
                abortUploadSuccess: '',
                abortUploadInProgress: true
            }
        case ABORT_UPLOAD_SUCCESS:
            return {
                ...state,
                abortUploadError: '',
                abortUploadSuccess: action.payload,
                abortUploadInProgress: false
            }
        case DELETE_PERMISSION_FAILURE:
            return {
                ...state,
                deletePermissionError: action.payload,
                deletePermissionSuccess: '',
                deletePermissionInProgress: false
            }
        case DELETE_PERMISSION_SUCCESS:
            return {
                ...state,
                deletePermissionError: '',
                deletePermissionInProgress: false,
                deletePermissionSuccess: action.payload
            }
        case DELETE_PERMISSION_REQUEST:
            return {
                ...state,
                deletePermissionInProgress: true,
                deletePermissionError: '',
                deletePermissionSuccess: ''
            }
        case GET_OWNER_FILES_FAILURE:
            return {
                ...state,
                getOwnerFilesError: action.payload,
                getOwnerFilesData: '',
                getOwnerFilesInProgress: false
            }
        case GET_OWNER_FILES_REQUEST:
            return {
                ...state,
                getOwnerFilesData: '',
                getOwnerFilesError: '',
                getOwnerFilesInProgress: true
            }
        case GET_OWNER_FILES_SUCCESS:
            return {
                ...state,
                getOwnerFilesData: action.payload,
                getOwnerFilesError: '',
                getOwnerFilesInProgress: false
            }
        case GET_FILE_INFORMATION_SUCCESS:
            return {
                ...state,
                getFileInformationInProgress: false,
                getFileInformationData: action.payload,
                getFileInformationError: ''
            }
        case GET_FILE_INFORMATION_REQUEST:
            return {
                ...state,
                getFileInformationInProgress: true,
                getFileInformationData: {},
                getFileInformationError: ''
            }
        case GET_FILE_INFORMATION_FAILURE:
            return {
                ...state,
                getFileInformationInProgress: false,
                getFileInformationData: {},
                getFileInformationError: action.payload
            }
        case SET_APPLY_TO_ALL:
            return {
                ...state,
                applyToAll: action.payload
            }
        case SET_CONFLICT_FILES:
            return {
                ...state,
                conflictFiles: action.payload
            }
        case SET_CURRENT_UPLOAD_SIZE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        uploadedSize: action.payload.uploadSize,
                        params: action.payload.params
                    }
                }
            }
        case PART_UPLOAD_COMPLETE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        partUploadComplete: action.payload.value
                    }
                }
            }
        /*case SET_RESUME_PARAMS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        params: action.payload.resumeParams,
                        status: action.payload.status
                    }
                }
            }*/
        case SPEED_DIAL_OPEN:
            return {
                ...state,
                speedDialIsOpen: action.payload
            }
        case SET_UPLOAD_ID:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        uploadID: action.payload.uploadID
                    }
                }
            }
        case SET_UPLOAD_STATUS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        status: action.payload.status
                    }
                }
            }
        case SET_UPLOAD_PROGRESS:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload.id]: {
                        ...state.fileProgress[action.payload.id],
                        progress: action.payload.progress
                    }
                }
            }
        case SUCCESS_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: 1 //Success
                    }
                }
            }
        case FAILURE_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    [action.payload]: {
                        ...state.fileProgress[action.payload],
                        status: 2 //Error
                    }
                }
            }
        case SET_UPLOAD_FILE:
            return {
                ...state,
                fileProgress: {
                    ...state.fileProgress,
                    ...modifyFiles(state.fileProgress,action.payload.data,action.payload.path)
                }
            }
        case CREATE_FOLDER_FAILURE:
            return {
                ...state,
                createFolderError: action.payload,
                createFolderStatus: '',
                createFolderInProgress: false
            }
        case CREATE_FOLDER_SUCCESS:
            return {
                ...state,
                createFolderError: '',
                createFolderStatus: action.payload,
                createFolderInProgress: false
            }
        case CREATE_FOLDER_REQUEST:
            return {
                ...state,
                createFolderInProgress: true
            }
        case OPEN_MODAL_UPLOAD_FILE:
            return {
                ...state,
                modalUploadFileIsOpen: action.payload
            }
        case SET_FILE_NAME:
            return {
                ...state,
                fileName: action.payload
            }
        case ADD_USER:
            return {
                ...state,
                addUser: action.payload
            }
        case SET_PERMISSION_DEFAULT_EMAIL:
            return {
                ...state,
                permissionDefaultEmail: action.payload
            }
        case SET_READ:
            return {
                ...state,
                read: action.payload
            }
        case SET_WRITE:
            return {
                ...state,
                write: action.payload
            }
        case SET_DELETE:
            return {
                ...state,
                delete: action.payload
            }
        case SET_ADMIN:
            return {
                ...state,
                admin: action.payload
            }
        case GET_PERMISSION_FAILURE:
            return {
                ...state,
                getPermissionError: action.payload,
                getPermissionStatus: [],
                getPermissionInProgress: false
            }
        case GET_PERMISSION_SUCCESS:
            return {
                ...state,
                getPermissionError: '',
                getPermissionStatus: action.payload,
                getPermissionInProgress: false
            }
        case GET_PERMISSION_REQUEST:
            return {
                ...state,
                getPermissionInProgress: true
            }
        case SET_PERMISSION_FAILURE:
            return {
                ...state,
                setPermissionError: action.payload,
                setPermissionInProgress: false,
                setPermissionStatus: ''
            }
        case SET_PERMISSION_SUCCESS:
            return {
                ...state,
                setPermissionStatus: action.payload,
                setPermissionInProgress: false,
                setPermissionError: ''
            }
        case SET_PERMISSION_REQUEST:
            return {
                ...state,
                setPermissionInProgress: true
            }
        case REFRESH_PERMISSION_ERROR:
            return {
                ...state,
                setPermissionError: ''
            }
        case OPEN_FORM_SET_PERMISSION:
            return {
                ...state,
                formSetPermissionIsOpen: action.payload
            }
        case OPEN_MODAL_SET_PERMISSION:
            return {
                ...state,
                modalSetPermissionIsOpen: action.payload
            }
        case OPEN_MODAL_CREATE_FOLDER:
            return {
                ...state,
                modalCreateFolderIsOpen: action.payload
            }
        case FETCH_FILE_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_FILE_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: '',
                path: action.path
            }
        case FETCH_FILE_FAILURE:
            return {
                ...state,
                loading: false,
                response: [],
                error: action.payload
            }
        case STORE_FILE_UPLOAD:
            return {
                ...state,
                file: action.payload
            }
        case UPLOAD_FILE_REQUEST:
            return {
                ...state,
                uploadInProgress: true
            }
        case UPLOAD_FILE_SUCCESS:
            return {
                ...state,
                uploadInProgress: false,
                uploadStatus: action.payload,
                uploadError: ''
            }
        case UPLOAD_FILE_FAILURE:
            return {
                ...state,
                uploadInProgress: false,
                uploadStatus: '',
                uploadError: action.payload
            }
        case DELETE_FILE_REQUEST:
            return {
                ...state,
                deleteInProgress: true
            }
        case DELETE_FILE_FAILURE:
            return {
                ...state,
                deleteInProgress: false,
                deleteStatus: '',
                deleteError: action.payload
            }
        case DELETE_FILE_SUCCESS:
            return {
                ...state,
                deleteInProgress: false,
                deleteStatus: action.payload,
                deleteError: ''
            }
        default: return state
    }
}

export default reducer