import {
    OPEN_HOME_DRAWER,
    SET_NAVIGATION_TITLE,
    TOGGLE_PROGRESS_BAR,
    TOGGLE_ACCOUNT_OPTIONS,
    OPEN_USER_INFORMATION,
    SET_PROGRESS_BAR_VISIBILITY,
    OPEN_CHANGE_PASSWORD_FORM,
    OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE,
    OPEN_DELETE_FILE_DIALOG,
    OPEN_DELETE_USER_DIALOG,
    OPEN_GENERATE_PASSWORD_DIALOG,
    OPEN_CONFLICT_FILE_MODAL,
    OPEN_ALERT_FILE_DELETE_FAILURE,
    OPEN_FILE_INFORMATION_MODAL,
    OPEN_UPDATE_USER_INFORMATION_FORM,
    OPEN_FILE_SIZE_UNIT_DROPDOWN,
    SET_UNIT,
    RETRY_TOKEN,
    RENEW_TOKEN_EXPIRED,
    CONTROL_RESET,
    SHOW_SEARCH_DROPDOWN,
    ALLOW_FILE_UPLOAD,
    ALLOW_FOLDER_UPLOAD,
} from './controlTypes'

const initialState = {
    homeDrawerIsOpen: false,
    navigationTitle: 'Storage',
    progressBarIsOpen: false,
    accountOptionsIsOpen: '',
    userInformationIsOpen: false,
    changePasswordFormIsOpen: false,
    openAlertFileUploadModalFailure: false,
    openDeleteFileDialog: false,
    openDeleteUserDialog: false,
    openGeneratePasswordDialog: false,
    openConflictFileModal: false,
    openAlertFileDeleteFailure: false,
    openFileInformationModal: false,
    openUpdateUserInformationForm: false,
    openFileSizeUnitDropDown: false,
    setUnit: '',
    retryToken: false,
    renewTokenExpired: false,
    showSearchDropdown: false,
    allowFileUpload: true,
    allowFolderUpload: true
}

const reducer = (state = initialState, action) => {

    switch(action.type){
        case ALLOW_FILE_UPLOAD:
            return {
                ...state,
                allowFileUpload: action.payload
            }
        case ALLOW_FOLDER_UPLOAD:
            return {
                ...state,
                allowFolderUpload: action.payload
            }
        case SHOW_SEARCH_DROPDOWN:
            return {
                ...state,
                showSearchDropdown: action.payload
            }
        case CONTROL_RESET:
            return {
                ...state,
                ...initialState
            }
        case RENEW_TOKEN_EXPIRED:
            return {
                ...state,
                renewTokenExpired: action.payload
            }
        case RETRY_TOKEN:
            return {
                ...state,
                retryToken: action.payload
            }
        case SET_UNIT:
            return {
                ...state,
                setUnit: action.payload
            }
        case OPEN_FILE_SIZE_UNIT_DROPDOWN:
            return {
                ...state,
                openFileSizeUnitDropDown: action.payload
            }
        case OPEN_UPDATE_USER_INFORMATION_FORM:
            return {
                ...state,
                openUpdateUserInformationForm: action.payload
            }
        case OPEN_FILE_INFORMATION_MODAL:
            return {
                ...state,
                openFileInformationModal: action.payload
            }
        case OPEN_ALERT_FILE_DELETE_FAILURE:
            return {
                ...state,
                openAlertFileDeleteFailure: action.payload
            }
        case OPEN_CONFLICT_FILE_MODAL:
            return {
                ...state,
                openConflictFileModal: action.payload
            }
        case OPEN_GENERATE_PASSWORD_DIALOG:
            return {
                ...state,
                openGeneratePasswordDialog: action.payload
            }
        case OPEN_DELETE_USER_DIALOG:
            return {
                ...state,
                openDeleteUserDialog: action.payload
            }
        case OPEN_DELETE_FILE_DIALOG:
            return {
                ...state,
                openDeleteFileDialog: action.payload
            }
        case OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE:
            return {
                ...state,
                openAlertFileUploadModalFailure: action.payload
            }
        case OPEN_CHANGE_PASSWORD_FORM:
            return {
                ...state,
                changePasswordFormIsOpen: action.payload
            }
        case SET_PROGRESS_BAR_VISIBILITY:
            return {
                ...state,
                progressBarIsOpen: action.payload
            }
        case OPEN_USER_INFORMATION:
            return {
                ...state,
                userInformationIsOpen: action.payload
            }
        case TOGGLE_ACCOUNT_OPTIONS:
            return {
                ...state,
                accountOptionsIsOpen: action.payload
            }
        case OPEN_HOME_DRAWER:
            return {
                ...state,
                homeDrawerIsOpen: action.payload
            }
        case SET_NAVIGATION_TITLE:
            return {
                ...state,
                navigationTitle: action.payload
            }
        case TOGGLE_PROGRESS_BAR:
            return {
                ...state,
                progressBarIsOpen: !state.progressBarIsOpen
            }
        default: return state
    }
}

export default reducer