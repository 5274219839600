import React from 'react'
import { connect } from 'react-redux'
import Breadcrumbs from '@material-ui/core/Breadcrumbs'
import Button from '@material-ui/core/Button';
import Link from '@material-ui/core/Link'
import { makeStyles } from '@material-ui/core/styles'
import { useHistory } from 'react-router-dom'

import { 
    fetchFiles
} from '../redux'

const slugify = require('slugify')

const useStyles = makeStyles((theme) => ({
    breadcrumb: {
        marginLeft: "20px",
        top: "2px"
    },
    buttonCase: {
        textTransform: 'none',
    }
  }));


const FileContainerPathBreadcrumb = ({response,fetchFiles}) => {

    const history = useHistory()
    const classes = useStyles()
    let path = response.path.split('/')
    path.splice(path.length-1,1)

    const handleClick = index => {
        
        let slugifyPath = `/storage/${path.slice(0,index+1).join('/')}/`;
        
        let slugifyPathArray =  slugifyPath.split('/');
        
        slugifyPathArray = slugifyPathArray.map(path=>{
            //path = slugify(path,'_');
            path = encodeURI(path);
            return path;
        });
        
        slugifyPath = slugifyPathArray.join('/');
        history.push(slugifyPath);
        
        //fetchFiles(`${path.slice(0,index+1).join('/')}/`)
    }


    return (
        <Breadcrumbs className={classes.breadcrumb} aria-label="breadcrumb">
            <Button className={classes.buttonCase}color="inherit" onClick={()=>{
                    history.push('/storage/default')
                    fetchFiles()
                }}>
                ...
            </Button>
            {path.map((path,index)=>
                <Button className={classes.buttonCase} color="inherit" onClick={()=>handleClick(index)}>
                    {path}
                </Button>
            )}
        </Breadcrumbs>
    )
}

const mapStateToProps = state => {
    return {
        response: state.file
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchFiles: (value) => dispatch(fetchFiles(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileContainerPathBreadcrumb)