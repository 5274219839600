import { size } from 'lodash'

export const modifyFiles = (existingFiles, files, path) => {
    let fileToUpload = {}
    for (let i=0; i<files.length; i++) {
        const id = size(existingFiles) + i + 1
        fileToUpload = {
            ...fileToUpload,
            [id]: {
                id,
                file: files[i],
                progress: 0,
                status: 0,
                path: path+files[i].webkitRelativePath.replace(files[i].name,'')
            }
        }
    }

    

    return fileToUpload
}

export const setEtag = (existingEtag, newEtag) => {
    if(!existingEtag){
        return newEtag
    }else{
        return existingEtag.concat(newEtag)
    }
}

export const deleteFile = (existingFiles, id) => {

    let fileToUpload = existingFiles

    delete fileToUpload[id]

    return fileToUpload
}