import LoginForm from './LoginContainerForm'
import React from 'react'
import { connect } from 'react-redux'
import { fetchLogin } from '../redux'
import { useHistory } from 'react-router-dom'
import '../css/login.css';



function LoginContainer({ response , fetchLogin}) {

    const history = useHistory()

    if(response.loggedIn===true){
        
        history.push('/')
    }

    return (
        <div className="Login">
            <LoginForm />
        </div>
    )
}

const mapStateToProps = state => {
    return {
        response: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchLogin: (values) => dispatch(fetchLogin(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(LoginContainer)