import React from 'react'
import { connect } from 'react-redux'
import { 
  Grid,
  IconButton,
  Button,
  DialogActions
} from '@material-ui/core'
import {
  Delete,
  VpnKey,
  Edit
} from '@material-ui/icons'
import TextField from '@material-ui/core/TextField'
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import { 
    red
} from '@material-ui/core/colors';
import Tooltip from '@material-ui/core/Tooltip'
import { useForm } from 'react-hook-form'

import {
  fetchUsers, 
  updateUser,
  openUserInformation,
  openDeleteUserDialog,
  openGeneratePasswordDialog,
  openUpdateUserInformationForm
} from '../redux'

import DropdownFileSizeUnit from './DropdownFileSizeUnit'



  const useStyles = makeStyles((theme) => ({
    paper: {
      top: '35%',
      left: '25%',
      outline: 'none',
      position: 'absolute',
      width: '50%',
      //height: '30%',
      backgroundColor: theme.palette.background.paper,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    },
    formGroup: {
      width: '100%'
    },
    textFieldClass: {
      width: 60,
      marginTop: theme.spacing(2.5),
    }
  }));



const UserInformationForm = ({ registerState, control, openUserInformation, openDeleteUserDialog, openGeneratePasswordDialog, updateUser, fetchUsers, openUpdateUserInformationForm }) => {

  const classes = useStyles();

  const { handleSubmit, register, errors } = useForm()

  const onSubmit = values => {
    updateUser(values)
  }

  const firstName = registerState.userInformation.firstName
  const lastName = registerState.userInformation.lastName
  const companyName = registerState.userInformation.companyName
  const email = registerState.userInformation.email
  const adminUser = registerState.userInformation.adminUser
  let storageLimit = registerState.userInformation.storageLimit

  let storageLimitString = ''

  if(storageLimit>=1099511627776){
    storageLimit = storageLimit/1099511627776
    storageLimitString = `${storageLimit} TB`
  }else if(storageLimit>=1073741824){
    storageLimit = storageLimit/1073741824
    storageLimitString = `${storageLimit} GB`
  }else if(storageLimit>=1048576){
    storageLimit = storageLimit/1048576
    storageLimitString = `${storageLimit} MB`
  }else{
    storageLimit = storageLimit/1024
    storageLimitString = `${storageLimit} KB`
  }
  localStorage.setItem('setUserEmail',email)
    
  const body = (
    <div className={classes.paper}>
      <Grid container spacing ={2}>
          <Grid item xs={9}>
            <h2 id="simple-modal-title">{lastName}, {firstName}</h2>
            <p id="simple-modal-description">
                {companyName}
            </p>
            <p>{email}</p>
          </Grid>
          <Grid alignItems="center" item xs={1}>
            <Tooltip title="Generate new password">
              <IconButton color="inherit">
                <VpnKey fontSize="medium" onClick={()=>openGeneratePasswordDialog(true)}/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid alignItems="center" item xs={1}>
            <Tooltip title="Edit Information">
              <IconButton color="inherit">
                <Edit fontSize="medium" onClick={()=>openUpdateUserInformationForm(true)}/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid alignItems="center" item xs={1}>
            <Tooltip title="Delete user">
              <IconButton color="inherit">
                <Delete fontSize="medium" onClick={()=>openDeleteUserDialog(true)} style={{ color: red[500] }}/>
              </IconButton>
            </Tooltip>
          </Grid>
          <Grid item xs={12}>
            <div>
              <Typography variant="h9">
                {adminUser?"Administrator":"Standard User"}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
          {
              (control.openUpdateUserInformationForm===false)
              ?<div>
                <div>
                  <Typography variant="h9">
                    Storage Limit
                  </Typography>
                  <p>{storageLimitString}</p>
                </div>
              </div>
              :<form onSubmit={handleSubmit(onSubmit)} className={classes.formGroup}>
                <div>
                  <div>
                    <Typography variant="h9">
                      Storage Limit
                    </Typography>
                    <br />
                    <br />
                    <Grid container>
                      <Grid item  xs={2} >
                        <TextField className={classes.textFieldClass} autoFocus margin="dense" name="storageLimit" type="text" defaultValue={storageLimit} placeholder="Storage Limit" inputRef={register} />
                      </Grid>
                      <Grid item  xs={1} >
                        <DropdownFileSizeUnit />
                      </Grid>
                    </Grid>
                  </div>
                </div>
                <DialogActions>
                    <Button 
                      onClick={()=>{
                        openUpdateUserInformationForm(false)
                      }} 
                      color="secondary">
                        Cancel
                    </Button>
                    <Button color="primary" type="submit">
                        Update
                    </Button>
                </DialogActions>
              </form>
            }
          </Grid>
      </Grid>
    </div>
  );

  return (
    <Modal
        open={control.userInformationIsOpen}
        onClose={()=>{
            openUserInformation(false)
            openUpdateUserInformationForm(false)
          }}
        aria-labelledby="simple-modal-title"
        aria-describedby="simple-modal-description"
      >{body}</Modal>
    
    
  )
}

const mapStateToProps = state => {
  return {
      control: state.control,
      registerState: state.register
  }
}

const mapDispatchToProps = dispatch => {
  return {
    openUserInformation: (values) => dispatch(openUserInformation(values)),
    openDeleteUserDialog: (values) => dispatch(openDeleteUserDialog(values)),
    openGeneratePasswordDialog: (values) => dispatch(openGeneratePasswordDialog(values)),
    updateUser: (values) => dispatch(updateUser(values)),
    fetchUsers: () => dispatch(fetchUsers()),
    openUpdateUserInformationForm: (values) => dispatch(openUpdateUserInformationForm(values))
  }
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(UserInformationForm)