import React from 'react'
import { connect } from 'react-redux'
import { 
    openDeleteFileDialog,
    deleteFile
} from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

const DeleteFileDialogForm = ({control, openDeleteFileDialog, deleteFile}) => {
    
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = values => deleteFile();

  return (
    <Dialog open={control.openDeleteFileDialog} onClose={()=>openDeleteFileDialog(false)} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Delete file</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to delete the file? This action cannot be reverted.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>openDeleteFileDialog(false)} color="secondary">
                Cancel
            </Button>
            <Button color="primary" type="submit">
                Delete
            </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

const mapStateToProps = state => {
    return {
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openDeleteFileDialog: (values) => dispatch(openDeleteFileDialog(values)),
        deleteFile: () => dispatch(deleteFile())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DeleteFileDialogForm)