import React from 'react'
import { connect } from 'react-redux'
import {
    openChangePasswordForm,
    changePassword
} from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { 
    Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme =>({
    errorLabel: {
      fontSize: 13,
      color: "red"
    }
}));

const ChangePasswordForm = ({control, registerPassword, openChangePasswordForm, changePassword}) => {
    
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = values => {
      changePassword(values)
  }

  const classes = useStyles()

  return (
    <Dialog open={control.changePasswordFormIsOpen} onClose={()=>openChangePasswordForm(false)} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Change Password</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <div>
                <div>
                    <TextField autoFocus margin="dense" name="oldPassword" type="password" placeholder="Old Password" inputRef={register} />
                </div>
                <div>
                    <TextField autoFocus margin="dense" name="newPassword" type="password" placeholder="New Password" inputRef={register} />
                </div>
                <div>
                    <TextField autoFocus margin="dense" name="newPasswordRepeat" type="password" placeholder="Repeat New Password" inputRef={register} />
                </div>
            </div>
            <DialogContentText className={classes.errorLabel}>
                {registerPassword.changePasswordError}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>openChangePasswordForm(false)} color="secondary">
                Cancel
            </Button>
            <Button color="primary" type="submit">
                Update
            </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

const mapStateToProps = state => {
    return {
        control: state.control,
        registerPassword: state.register
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openChangePasswordForm: (values) => dispatch(openChangePasswordForm(values)),
        changePassword: (values) => dispatch(changePassword(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ChangePasswordForm)