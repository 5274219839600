import {
    FETCH_LOGIN_FAILURE,
    FETCH_LOGIN_REQUEST,
    FETCH_LOGIN_SUCCESS,
    SET_LOGIN_STATE,
    RENEW_TOKEN_FAILURE,
    RENEW_TOKEN_REQUEST,
    RENEW_TOKEN_SUCCESS,
    LOGIN_RESET
} from './loginTypes'

const initialState = {
    loading: false,
    response: '',
    error: '',
    loggedIn: localStorage.getItem('loggedIn'),
    renewTokenError: '',
    renewTokenSuccess: '',
    renewTokenInProgress: false
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case LOGIN_RESET:
            return {
                ...state,
                ...initialState
            }
        case RENEW_TOKEN_FAILURE:
            return {
                ...state,
                renewTokenError: action.payload,
                renewTokenSuccess: '',
                renewTokenInProgress: false
            }
        case RENEW_TOKEN_SUCCESS:
            return {
                ...state,
                renewTokenError: '',
                renewTokenSuccess: action.payload,
                renewTokenInProgress: false
            }
        case RENEW_TOKEN_REQUEST:
            return {
                ...state,
                renewTokenError: '',
                renewTokenSuccess: '',
                renewTokenInProgress: true
            }
        case SET_LOGIN_STATE:
            return {
                ...state,
                loggedIn: action.payload
            }
        case FETCH_LOGIN_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_LOGIN_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: ''
            }
        case FETCH_LOGIN_FAILURE:
            return {
                ...state,
                loading: false,
                response: '',
                error: action.payload
            }
        default: return state
    }
}

export default reducer