import React from 'react'
import { connect } from 'react-redux'
import { 
    openModalUploadFile,
    storeFileUpload,
    uploadFile,
    setUploadFile,
    setProgressBarVisibility,
    checkFileConflictBeforeUpload,
    getOwnerFiles,
    getOwnerFilesSuccess,
    checkFolderExist,
    setFolderExistIndicator,
    allowFileUpload,
    allowFolderUpload,
 } from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button,
    Box
} from '@material-ui/core'
import { stubTrue } from 'lodash'




const UploadFileModal = ({ control, response, allowFileUpload, allowFolderUpload, setFolderExistIndicator, checkFolderExist, openModalUploadFile, storeFileUpload, getOwnerFilesSuccess, uploadFile, setUploadFile, setProgressBarVisibility, checkFileConflictBeforeUpload, getOwnerFiles }) => {

  let listOfFileArray;

  let fileSelectedHandler = event => {
    
    //console.log(response.response)
    //console.log('List of files')
    //console.log(event.target.files)
    listOfFileArray = Object.values(event.target.files)
    //console.log('Before calling function')
    if(listOfFileArray.length>0){
      //console.log(listOfFileArray[0].webkitRelativePath.slice(0,listOfFileArray[0].webkitRelativePath.indexOf('/',0)))
      if(listOfFileArray[0].webkitRelativePath!='' && listOfFileArray[0].webkitRelativePath!=null){
        checkFolderExist(listOfFileArray[0].webkitRelativePath.slice(0,listOfFileArray[0].webkitRelativePath.indexOf('/',0)),response.response)
      }
      storeFileUpload(event.target.files)
      getOwnerFiles()
    }else{
      setFolderExistIndicator(0)
    }
  }
  
  
  let uploadPath = response.path

  if(!(response.path.startsWith('Shared by other Users/') || response.path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
    
    uploadPath=`Shared by other Users/${localStorage.getItem('email')}/${response.path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`

  }

  return (
    <div>
      <Dialog 
      open={response.modalUploadFileIsOpen} 
      onClose={()=>{
        openModalUploadFile(false)
        setFolderExistIndicator(0)
        allowFolderUpload(true)
        allowFileUpload(true)
        }
      } aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Upload file</DialogTitle>
            <DialogContent>
              <Box display="flex" flexDirection="column" width="100%" height="50%" justifyContent="space-evenly" alignItems="center">
                {
                  (control.allowFileUpload)?<Button fullWidth color="primary" onClick={()=>allowFolderUpload(false)}>Upload File</Button>:null
                }
                {
                  (control.allowFolderUpload)?<Button fullWidth color="secondary" onClick={()=>allowFileUpload(false)}>Upload Folder</Button>:null
                }
                {
                  (!control.allowFolderUpload)?<input type="file" id="file_uploads" name="file_uploads" multiple onChange={fileSelectedHandler}/>:null
                }
                {
                  (!control.allowFileUpload)?<input type="file" id="folder_uploads" name="folder_uploads" multiple onChange={fileSelectedHandler} directory="" webkitdirectory=""/>:null
                }
              </Box>
                
                <DialogContentText>
                    {response.uploadError.toString()}
                </DialogContentText>
                {
                  (response.getOwnerFilesData===false)
                  ?<DialogContentText>
                    <span style={{color: 'red'}}>Not enough storage available</span>
                  </DialogContentText>
                  :null
                }
                {
                  (response.folderExists===1)
                  ?<DialogContentText>
                    <span style={{color: 'red'}}>Folder with the same name exists. Please rename the folder before uploading.</span>
                  </DialogContentText>
                  :null
                }
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>{
                  openModalUploadFile(false)
                  storeFileUpload({})
                  getOwnerFilesSuccess()
                  setFolderExistIndicator(0)
                  allowFolderUpload(true)
                  allowFileUpload(true)
                  }} color="secondary">
                    Cancel
                </Button>
                <Button color="primary" 
                    disabled={((Object.keys(response.file).length != 0) && (response.getOwnerFilesData===true) && (response.folderExists!=1))?false:true}
                    onClick={()=>{
                        //setUploadFile(response.file,uploadPath) //COMMENT OUT TEMPORARILY IN CASE CHECK CONFLICT DOESN't WORK
                        checkFileConflictBeforeUpload()
                        openModalUploadFile(false)
                        setProgressBarVisibility(true)
                        setFolderExistIndicator(0)
                        allowFolderUpload(true)
                        allowFileUpload(true)
                      }
                    }>
                    Upload
                </Button>
            </DialogActions>
        
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.file,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        uploadFile: (file,path) => dispatch(uploadFile(file,path)),
        storeFileUpload: (values) => dispatch(storeFileUpload(values)),
        openModalUploadFile: (values) => dispatch(openModalUploadFile(values)),
        setUploadFile: (file,path) => dispatch(setUploadFile(file,path)),
        setProgressBarVisibility: (values) => dispatch(setProgressBarVisibility(values)),
        checkFileConflictBeforeUpload: () => dispatch(checkFileConflictBeforeUpload()),
        getOwnerFiles: () => dispatch(getOwnerFiles()),
        getOwnerFilesSuccess: (values) => dispatch(getOwnerFilesSuccess(values)),
        checkFolderExist: (newfolder,currentfolders) => dispatch(checkFolderExist(newfolder,currentfolders)),
        setFolderExistIndicator: (values) => dispatch(setFolderExistIndicator(values)),
        allowFileUpload: (values) => dispatch(allowFileUpload(values)),
        allowFolderUpload: (values) => dispatch(allowFolderUpload(values)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UploadFileModal)