import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { useHistory } from 'react-router-dom'

import { 
    openHomeDrawer,
    setNavigationTitle
} from '../redux'



import { 
    Storage,
    ThumbUp,
    HowToReg,
    People,
    Home
} from '@material-ui/icons';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
});

const HomePageMenuDrawer = ({ response, openHomeDrawer, setNavigationTitle }) => {
  const classes = useStyles();

  const history = useHistory();

  const isAdmin = localStorage.getItem('isAdmin')
  

  const toggleDrawer = (open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    openHomeDrawer(open)
  };

  const storageHandleClick = () => {
      history.push('/storage/default')
      setNavigationTitle('Storage')
  }

  const homeHandleClick = () => {
    history.push('')
    setNavigationTitle('Home')
  }

  const usersHandleClick = () => {
    history.push('/users')
    setNavigationTitle('Users')
  }

  const list = () => (
    <div
      className={classes.list}
      role="presentation"
      onClick={toggleDrawer(false)}
      onKeyDown={toggleDrawer(false)}
    >
      <List>
        <ListItem button onClick={storageHandleClick}>
            <ListItemIcon>
                <Storage />
            </ListItemIcon>
            <ListItemText primary="Storage" />
        </ListItem>
      </List>
      <Divider />
      {isAdmin==='true' ? 
      <List>
        <ListItem button onClick={usersHandleClick}>
            <ListItemIcon>
                <People />
            </ListItemIcon>
            <ListItemText primary="Users"/>
        </ListItem>
      </List>
      :null }
    </div>
  );

  return (
    <div>
        <Drawer anchor="left" open={response.homeDrawerIsOpen} onClose={toggleDrawer(false)}>
            {list()}
        </Drawer>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openHomeDrawer: (value) => dispatch(openHomeDrawer(value)),
        setNavigationTitle: (value) =>dispatch(setNavigationTitle(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePageMenuDrawer)