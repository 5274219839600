export const FETCH_FILE_REQUEST = 'FETCH_FILE_REQUEST'
export const FETCH_FILE_SUCCESS = 'FETCH_FILE_SUCCESS'
export const FETCH_FILE_FAILURE = 'FETCH_FILE_FAILURE'
export const STORE_FILE_UPLOAD = 'STORE_FILE_UPLOAD'
export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST'
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS'
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE'
export const DELETE_FILE_REQUEST = 'DELETE_FILE_REQUEST'
export const DELETE_FILE_SUCCESS = 'DELETE_FILE_SUCCESS'
export const DELETE_FILE_FAILURE = 'DELETE_FILE_FAILURE'
export const OPEN_MODAL_CREATE_FOLDER = 'OPEN_MODAL_CREATE_FOLDER'
export const CREATE_FOLDER_REQUEST = 'CREATE_FOLDER_REQUEST'
export const CREATE_FOLDER_SUCCESS = 'CREATE_FOLDER_SUCCESS'
export const CREATE_FOLDER_FAILURE = 'CREATE_FOLDER_FAILURE'
export const SET_PERMISSION_REQUEST = 'SET_PERMISSION_REQUEST'
export const SET_PERMISSION_SUCCESS = 'SET_PERMISSION_SUCCESS'
export const SET_PERMISSION_FAILURE = 'SET_PERMISSION_FAILURE'
export const REFRESH_PERMISSION_ERROR = 'REFRESH_PERMISSION_ERROR'
export const OPEN_MODAL_SET_PERMISSION = 'OPEN_MODAL_SET_PERMISSION'
export const GET_PERMISSION_REQUEST = 'GET_PERMISSION_REQUEST'
export const GET_PERMISSION_SUCCESS = 'GET_PERMISSION_SUCCESS'
export const GET_PERMISSION_FAILURE = 'GET_PERMISSION_FAILURE'
export const OPEN_FORM_SET_PERMISSION = 'OPEN_FORM_SET_PERMISSION'
export const SET_READ = 'SET_READ'
export const SET_WRITE = 'SET_WRITE'
export const SET_DELETE = 'SET_DELETE'
export const SET_ADMIN = 'SET_ADMIN'
export const SET_PERMISSION_DEFAULT_EMAIL = 'SET_PERMISSION_DEFAULT_EMAIL'
export const ADD_USER = 'ADD_USER'
export const SET_FILE_NAME = 'SET_FILE_NAME'
export const OPEN_MODAL_UPLOAD_FILE = 'OPEN_MODAL_UPLOAD_FILE'
export const SET_UPLOAD_FILE = 'SET_UPLOAD_FILE'
export const SET_UPLOAD_PROGRESS = 'SET_UPLOAD_PROGRESS'
export const SUCCESS_UPLOAD_FILE = 'SUCCESS_UPLOAD_FILE'
export const FAILURE_UPLOAD_FILE = 'FAILURE_UPLOAD_FILE'
export const SET_UPLOAD_ID = 'SET_UPLOAD_ID'
export const DELETE_UPLOAD_FILE_FROM_QUEUE = 'DELETE_UPLOAD_FILE_FROM_QUEUE'
export const SPEED_DIAL_OPEN = 'SPEED_DIAL_OPEN'
export const SET_UPLOAD_STATUS = 'SET_UPLOAD_STATUS'
export const SET_RESUME_PARAMS = 'SET_RESUME_PARAMS'
export const SET_CURRENT_UPLOAD_SIZE = 'SET_CURRENT_UPLOAD_SIZE'
export const SET_CONFLICT_FILES = 'SET_CONFLICT_FILES'
export const SET_APPLY_TO_ALL = 'SET_APPLY_TO_ALL'
export const GET_FILE_INFORMATION_REQUEST = 'GET_FILE_INFORMATION_REQUEST'
export const GET_FILE_INFORMATION_SUCCESS = 'GET_FILE_INFORMATION_SUCCESS'
export const GET_FILE_INFORMATION_FAILURE = 'GET_FILE_INFORMATION_FAILURE'
export const GET_OWNER_FILES_REQUEST = 'GET_OWNER_FILES_REQUEST'
export const GET_OWNER_FILES_SUCCESS = 'GET_OWNER_FILES_SUCCESS'
export const GET_OWNER_FILES_FAILURE = 'GET_OWNER_FILES_FAILURE'
export const DELETE_PERMISSION_REQUEST = 'DELETE_PERMISSION_REQUEST'
export const DELETE_PERMISSION_SUCCESS = 'DELETE_PERMISSION_SUCCESS'
export const DELETE_PERMISSION_FAILURE = 'DELETE_PERMISSION_FAILURE'
export const ABORT_UPLOAD_REQUEST = 'ABORT_UPLOAD_REQUEST'
export const ABORT_UPLOAD_SUCCESS = 'ABORT_UPLOAD_SUCCESS'
export const ABORT_UPLOAD_FAILURE = 'ABORT_UPLOAD_FAILURE'
export const PART_UPLOAD_COMPLETE = 'PART_UPLOAD_COMPLETE'
export const FILE_RESET = 'FILE_RESET'
export const CHECK_FOLDER_EXIST = 'CHECK_FOLDER_EXIST'
//export const SET_UPLOAD_PATH = 'SET_UPLOAD_PATH'
//export const SET_INTERNET_STATUS = 'SET_INTERNET_STATUS'