import axios from 'axios';

import {
  FETCH_LOGIN_REQUEST,
  FETCH_LOGIN_SUCCESS,
  FETCH_LOGIN_FAILURE,
  SET_LOGIN_STATE,
  RENEW_TOKEN_REQUEST,
  RENEW_TOKEN_SUCCESS,
  RENEW_TOKEN_FAILURE,
  LOGIN_RESET
} from './loginTypes';

import { 
  renewTokenExpired
} from '../controls/controlActions';


export const loginReset = () => {
  return {
    type: LOGIN_RESET
  }
}

export const renewTokenRequest = () => {
  return {
    type: RENEW_TOKEN_REQUEST
  }
}

export const renewTokenSuccess = (value) => {
  return {
    type: RENEW_TOKEN_SUCCESS,
    payload: value
  }
}

export const renewTokenFailure = (value) => {
  return {
    type: RENEW_TOKEN_FAILURE,
    payload: value
  }
}

export const renewToken = (resolve) => {

  const refreshToken = localStorage.getItem('refreshToken')

  return (dispatch) => {
      dispatch(renewTokenRequest())
      axios({
          method: 'post',
          url: `${process.env.REACT_APP_API_BASE_URL}/user/renewToken`,
          data: {
              refreshToken: refreshToken
          }
      })
          .then(response => {
              
              const login = response.data
              localStorage.setItem('accessToken',login.token)
              localStorage.setItem('refreshToken',login.refreshtoken)
              localStorage.setItem('loggedIn',true)
              localStorage.setItem('lastName',login.lastName)
              localStorage.setItem('firstName',login.firstName)
              localStorage.setItem('companyName',login.companyName)
              localStorage.setItem('isAdmin',login.admin)
              localStorage.setItem('email',login.email)
              dispatch(renewTokenSuccess(true))
              dispatch(setLoginState(true))
              resolve()
          })
          .catch((error) => {
              const errorMessage = error.response.data.message
              dispatch(renewTokenFailure(errorMessage))
              dispatch(renewTokenExpired(true));
          })
  }
}

export const setLoginState = (value) => {
  return {
    type: SET_LOGIN_STATE,
    payload: value,
  };
};

export const fetchLoginRequest = () => {
  return {
    type: FETCH_LOGIN_REQUEST,
  };
};

const fetchLoginSuccess = (success) => {
  return {
    type: FETCH_LOGIN_SUCCESS,
    payload: success,
  };
};

const fetchLoginFailure = (error) => {
  return {
    type: FETCH_LOGIN_FAILURE,
    payload: error,
  };
};

export const fetchLogin = (values) => {
    return (dispatch) => {
        dispatch(fetchLoginRequest())
        axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/user/login`,
            data: {
                email: `${values.userEmail}`,
                password: `${values.password}`
            }
        })
            .then(response => {

                dispatch(renewTokenExpired(false))
                
                const login = response.data
                localStorage.setItem('accessToken',login.token)
                localStorage.setItem('refreshToken',login.refreshtoken)
                localStorage.setItem('loggedIn',true)
                localStorage.setItem('lastName',login.lastName)
                localStorage.setItem('firstName',login.firstName)
                localStorage.setItem('companyName',login.companyName)
                localStorage.setItem('isAdmin',login.admin)
                localStorage.setItem('email',login.email)
                dispatch(fetchLoginSuccess(login))
                dispatch(setLoginState(true))
                
            })
            .catch((error) => {
                const errorMessage = error.response.data.message
                dispatch(fetchLoginFailure(errorMessage))
            })
    }
}
