import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles'
import {
    Person
} from '@material-ui/icons'
import {
    Grid,
    List,
    ListItem, 
    ListItemIcon,
    ListItemText,
    Snackbar
} from '@material-ui/core'
import Fab from '@material-ui/core/Fab';
import { Add } from '@material-ui/icons';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';

import { 
    fetchUsers,
    openModalRegister,
    openUserInformation,
    setUserInformationDisplay,
    openAlertAddUserFailure,
    openAlertAddUserSuccess,
    openAlertDeleteUserSuccess,
    openAlertChangePasswordFailure,
    openAlertChangePasswordSuccess
} from '../redux'
import HomePageMenuDrawer from './HomePageDrawerContainer'
import AppBarContainer from './AppBarContainer'
import RegisterForm from './RegisterContainerForm'
import UserInformationForm from './UsersInformationContainer'
import DeleteUserDialogForm from './UserContainerDeleteUserConfirmation'
import GeneratePasswordDialogForm from './UserContainerGeneratePasswordConfirmation'

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
    root: {
      flexGrow: 1
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    buttonLabel: {
      marginLeft: "5px"
    },
    buttonIcon: {
      justifySelf: "flex-end"
    },
    extendedIcon: {
      marginRight: theme.spacing(1),
    },
    speedDial: {
      position: 'fixed',
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    }
  }));


const UsersContainer = ({ response , fetchUsers, openModalRegister, openUserInformation, setUserInformationDisplay, openAlertAddUserFailure, openAlertAddUserSuccess, openAlertDeleteUserSuccess, openAlertChangePasswordFailure, openAlertChangePasswordSuccess }) => {

    const classes = useStyles();

    useEffect(()=>{
        fetchUsers()
    }, [])
    

    return (
        <div>
            <Backdrop className={classes.backdrop} open={response.loading || response.generatePasswordInProgress || response.deleteUsersInProgress || response.changePasswordInProgress || response.usersInProgress }>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={response.openAlertChangePasswordSuccess} 
            autoHideDuration={6000} 
            onClose={()=>openAlertChangePasswordSuccess(false)}
            >
                <Alert onClose={()=>openAlertChangePasswordSuccess(false)} severity="success">
                    New password sent to the user's email.
                </Alert>
            </Snackbar>
            <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={response.openAlertChangePasswordFailure} 
            autoHideDuration={6000} 
            onClose={()=>openAlertChangePasswordFailure(false)}
            >
                <Alert onClose={()=>openAlertChangePasswordFailure(false)} severity="error">
                    Issue encountered while generating new password.
                </Alert>
            </Snackbar>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={response.openAlertDeleteUserSuccess} 
            autoHideDuration={6000} 
            onClose={()=>openAlertDeleteUserSuccess(false)}
            >
                <Alert onClose={()=>openAlertDeleteUserSuccess(false)} severity="success">
                    User deleted successfully!
                </Alert>
            </Snackbar>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={response.openAlertAddUserSuccess} 
            autoHideDuration={6000} 
            onClose={()=>openAlertAddUserSuccess(false)}
            >
                <Alert onClose={()=>openAlertAddUserSuccess(false)} severity="success">
                    User added successfully!
                </Alert>
            </Snackbar>
            <Snackbar
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={response.openAlertAddUserFailure} 
            autoHideDuration={6000} 
            onClose={()=>openAlertAddUserFailure(false)}
            >
                <Alert onClose={()=>openAlertAddUserFailure(false)} severity="error">
                    Issue encountered while adding a user.
                </Alert>
            </Snackbar>
            <UserInformationForm />
            <Fab className={classes.speedDial} color="secondary" aria-label="add">
                <Add onClick={() => openModalRegister(true)}/>
            </Fab>
            <RegisterForm />
            <HomePageMenuDrawer />
            <DeleteUserDialogForm />
            <GeneratePasswordDialogForm />
            <div>
                <AppBarContainer />
            </div>
            <br />
            <div>
                <Grid container justify="center" spacing={2}>
                    <Grid item xs={12} md={10}>
                        <div className={classes.demo}>
                            <List dense={false}>
                                {response.usersResponse.map(user=>
                                    <ListItem button onClick={()=>{
                                        openUserInformation(true)
                                        setUserInformationDisplay(user)
                                        }} divider>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={`${user.lastName}, ${user.firstName}`}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    </Grid>
                </Grid>
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        response: state.register
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchUsers: () => dispatch(fetchUsers()),
        openModalRegister: (values) => dispatch(openModalRegister(values)),
        openUserInformation: (values) => dispatch(openUserInformation(values)),
        setUserInformationDisplay: (values) => dispatch(setUserInformationDisplay(values)),
        openAlertAddUserFailure: (value) => dispatch(openAlertAddUserFailure(value)),
        openAlertAddUserSuccess: (value) => dispatch(openAlertAddUserSuccess(value)),
        openAlertDeleteUserSuccess: (value) => dispatch(openAlertDeleteUserSuccess(value)),
        openAlertChangePasswordFailure: (value) => dispatch(openAlertChangePasswordFailure(value)),
        openAlertChangePasswordSuccess: (value) => dispatch(openAlertChangePasswordSuccess(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(UsersContainer)