import React from 'react';
import Button from '@material-ui/core/Button';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import { 
    setLoginState,
    setNavigationTitle,
    openAccountOptions,
    logOut
} from '../redux'

const AccountOptions = ({ control, setLoginState, setNavigationTitle, openAccountOptions, logOut }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);

  const firstName = localStorage.getItem('firstName')
  const lastName = localStorage.getItem('lastName')

  const history = useHistory()

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    openAccountOptions(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    openAccountOptions(null);
  };

  const accountOnClick = () => {
    openAccountOptions(null);
    setNavigationTitle('My Account')
    history.push('/account')
  }

  const logOutOnClick = () => {
    openAccountOptions(null);
    setLoginState(false)
    setNavigationTitle('Storage')
    localStorage.setItem('accessToken','')
    localStorage.setItem('refreshToken','')
    localStorage.setItem('loggedIn', false)
    localStorage.setItem('isAdmin',null)
    localStorage.setItem('lastName',null)
    localStorage.setItem('firstName',null)
    logOut()
    history.push('/login')
  }

  return (
    <div>
      <Button color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
        {`${lastName}, ${firstName}`}
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={control.accountOptionsIsOpen}
        keepMounted
        open={Boolean(control.accountOptionsIsOpen)}
        onClose={handleClose}
      >
        <MenuItem onClick={accountOnClick}>My account</MenuItem>
        <MenuItem onClick={logOutOnClick}>Logout</MenuItem>
      </Menu>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.login,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoginState: (value) => dispatch(setLoginState(value)),
        setNavigationTitle: (value) => dispatch(setNavigationTitle(value)),
        openAccountOptions: (value) => dispatch(openAccountOptions(value)),
        logOut: () => dispatch(logOut())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(AccountOptions)