import React from 'react'
import { connect } from 'react-redux'
import Styles from '../css/UploadItem.module.css'
import { 
    PlayArrow,
    Cancel,
    Pause,
    Replay
} from '@material-ui/icons'
import { 
    IconButton, Icon
  } from '@material-ui/core'

import { 
    setUploadStatus,
    abortUpload
} from '../redux'

const UploadItem = props => {
    const { setUploadStatus, abortUpload } = props
    const { file, progress } = props.file
    const id = props.id
    const fileProgress = props.fileStatus.fileProgress[id]

    //console.log('Display progress upload file')
    
    return (
        <div className={Styles.wrapperItem}>
            <div className={Styles.leftSide}>
                    {//(fileProgress.status===2 || fileProgress.status===4)
                    (fileProgress.status===4)
                    ?<IconButton><Replay fontSize="small" onClick={()=>setUploadStatus(id,0)}/></IconButton>
                    :null
                    }    
            </div>
            <div className={Styles.leftSide}>
                <IconButton>
                    <Cancel fontSize="small" onClick={()=>{
                        abortUpload(id);
                    }}/>
                </IconButton>    
            </div>
            <div className={Styles.leftSide}>
                {//(fileProgress.status===2 || fileProgress.status===4)
                (fileProgress.status===4)
                    ?<div className={Styles.progressBarError}>
                        <div style={{ width: `${progress}%` }} />
                    </div>
                    :<div className={Styles.progressBar}>
                        <div style={{ width: `${progress}%` }} />
                    </div>
                }    
                <label className={Styles.labelName}>{file.name}</label>
            </div>
            <span className={Styles.percentage}>{progress}%</span>
        </div>
    )
}


const mapStateToProps = state => {
    return {
        control: state.control,
        fileStatus: state.file
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        setUploadStatus: (id,status) => dispatch(setUploadStatus(id,status)),
        abortUpload: (id) => dispatch(abortUpload(id))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(UploadItem)