import axios from 'axios';

import {
    FETCH_REGISTER_REQUEST,
    FETCH_REGISTER_SUCCESS,
    FETCH_REGISTER_FAILURE,
    FETCH_USERS_FAILURE,
    FETCH_USERS_SUCCESS,
    FETCH_USERS_REQUEST,
    OPEN_MODAL_REGISTER,
    SET_ADMIN_REGISTER,
    SET_USER_INFORMATION_DISPLAY,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_SUCCESS,
    OPEN_ALERT_CHANGE_PASSWORD_SUCCESS,
    OPEN_ALERT_CHANGE_PASSWORD_FAILURE,
    OPEN_ALERT_ADD_USER_SUCCESS,
    OPEN_ALERT_ADD_USER_FAILURE,
    DELETE_USERS_FAILURE,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    OPEN_ALERT_DELETE_USER_SUCCESS,
    GENERATE_PASSWORD_FAILURE,
    GENERATE_PASSWORD_REQUEST,
    GENERATE_PASSWORD_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    REGISTER_RESET
} from './registerTypes'

import {
  openChangePasswordForm,
  openUserInformation
} from '../'
import { 
  openDeleteUserDialog, 
  openGeneratePasswordDialog,
  openUpdateUserInformationForm,
  retryToken,
  renewTokenExpired
} from '../controls/controlActions';

import { 
  renewToken
} from '../login/loginActions';

const renewTokenWrapper = (dispatch) => new Promise((resolve,reject) => {
  dispatch(renewToken(resolve))
})


export const updateUserFailure = value => {
  return {
    type: UPDATE_USER_FAILURE,
    payload: value
  }
}

export const updateUserSuccess = value => {
  return {
    type: UPDATE_USER_SUCCESS,
    payload: value
  }
}

export const registerReset = () => {
  return {
    type: REGISTER_RESET
  }
}

export const updateUserRequest = () => {
  return {
    type: UPDATE_USER_REQUEST
  }
}

export const updateUser = (values) => {
  return (dispatch, getState) => {
    const state = getState();

    const email = state.register.userInformation.email;
    const sizeMultiplier = state.control.setUnit;


    const accessToken = localStorage.getItem('accessToken');

    dispatch(updateUserRequest());
    axios({
      method: 'put',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/${email}`,
      data: {
        storageLimit: values.storageLimit*sizeMultiplier
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        
        dispatch(updateUserSuccess(response));
        dispatch(setUserInformationDisplay(response.data));
        dispatch(openUpdateUserInformationForm(false));
        dispatch(fetchUsers());
        dispatch(retryToken(false))
      })
      .catch((error) => {
        console.log(error);
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(updateUser(values))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(updateUserFailure(error));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const generatePasswordFailure = value => {
  return {
    type: GENERATE_PASSWORD_FAILURE,
    payload: value
  }
}

export const generatePasswordRequest = () => {
  return {
    type: GENERATE_PASSWORD_REQUEST
  }
}

export const generatePasswordSuccess = value => {
  return {
    type: GENERATE_PASSWORD_SUCCESS,
    payload: value
  }
}

export const openAlertDeleteUserSuccess = value => {
  return {
    type: OPEN_ALERT_DELETE_USER_SUCCESS,
    payload: value
  }
}

export const deleteUsersFailure = value => {
  return {
    type: DELETE_USERS_FAILURE,
    payload: value
  }
}

export const deleteUsersRequest = () => {
  return {
    type: DELETE_USERS_REQUEST
  }
}

export const deleteUsersSuccess = value => {
  return {
    type: DELETE_USERS_SUCCESS,
    payload: value
  }
}

export const openAlertAddUserSuccess = value => {
  return {
    type: OPEN_ALERT_ADD_USER_SUCCESS,
    payload: value
  }
}

export const openAlertAddUserFailure = value => {
  return {
    type: OPEN_ALERT_ADD_USER_FAILURE,
    payload: value
  }
}

export const openAlertChangePasswordSuccess = value => {
  return {
    type: OPEN_ALERT_CHANGE_PASSWORD_SUCCESS,
    payload: value
  }
}

export const openAlertChangePasswordFailure = value => {
  return {
    type: OPEN_ALERT_CHANGE_PASSWORD_FAILURE,
    payload: value
  }
}

export const setUserInformationDisplay = value => {
    return {
        type: SET_USER_INFORMATION_DISPLAY,
        payload: value
    }
}

export const setAdminRegister = value => {
    return {
        type: SET_ADMIN_REGISTER,
        payload: value
    }
}

export const openModalRegister = value => {
    return {
        type: OPEN_MODAL_REGISTER,
        payload: value
    }
}

export const fetchUsersRequest = () => {
  return {
    type: FETCH_USERS_REQUEST,
  };
};

const fetchUsersSuccess = (success) => {
  return {
    type: FETCH_USERS_SUCCESS,
    payload: success,
  };
};

const fetchUsersFailure = (error) => {
  return {
    type: FETCH_USERS_FAILURE,
    payload: error,
  };
};


/*
export const fetchUsersFilter = (searchText) => {
  const accessToken = localStorage.getItem('accessToken');

  return (dispatch, getState) => {
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/${searchText}`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }).then((response) => {

    }).catch((error) => {
      const errorMessage = error.response.data.message;
        
      console.log(error.response.status)
      const state = getState()
      const retryOccurred = state.control.retryToken
        
      if(!retryOccurred && error.response.status===401){
        renewTokenWrapper(dispatch).then(() => {
          dispatch(fetchUsersFilter(searchText))
        })
        dispatch(retryToken(true))
      }else{
        dispatch(fetchUsersFailure(errorMessage));
        if(error.response.status===401){
          console.log('Need to logout')
          dispatch(renewTokenExpired(true));
        }
      }
    })
  }
}*/

export const fetchUsers = (searchText) => {
  console.log(searchText);
  const accessToken = localStorage.getItem('accessToken');

  return (dispatch, getState) => {
    dispatch(fetchUsersRequest());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
      params: {
        searchText: searchText
      }
    })
      .then((response) => {
        
        const users = response.data;
        console.log('User response data');
        console.log(users);
        dispatch(fetchUsersSuccess(users));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        const errorMessage = error.response.data.message;
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(fetchUsers())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(fetchUsersFailure(errorMessage));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const fetchRegisterRequest = () => {
  return {
    type: FETCH_REGISTER_REQUEST,
  };
};

const fetchRegisterSuccess = (success) => {
  return {
    type: FETCH_REGISTER_SUCCESS,
    payload: success,
  };
};

const fetchRegisterFailure = (error) => {
  return {
    type: FETCH_REGISTER_FAILURE,
    payload: error,
  };
};

const changePasswordRequest = () => {
    return {
        type: CHANGE_PASSWORD_REQUEST
    }
}

const changePasswordFailure = error => {
    return {
        type: CHANGE_PASSWORD_FAILURE,
        payload: error
    }
}

const changePasswordSuccess = success => {
    return {
        type: CHANGE_PASSWORD_SUCCESS,
        payload: success
    }
}

export const generatePassword = () => {
  return (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken')

    dispatch(generatePasswordRequest())

    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/generatepassword`,
      data: {
          email: localStorage.getItem('setUserEmail')
      },
      headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
      }
  })
      .then(response => {
          dispatch(generatePasswordSuccess(response.data))
          dispatch(openAlertChangePasswordSuccess(true))
          dispatch(openGeneratePasswordDialog(false))
          dispatch(retryToken(false))
      })
      .catch(error => {
          
        const errorMessage = error.response.data.message;
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(generatePassword())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(generatePasswordFailure(error.response.data.message))
          dispatch(openAlertChangePasswordFailure(true))
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      })
  }
}

export const changePassword = (values) => {
    return (dispatch, getState) => {

        const accessToken = localStorage.getItem('accessToken')

        if(!(values.newPassword===values.newPasswordRepeat)){
            dispatch(changePasswordFailure('New passwords don\'t match'))
        }else if( values.oldPassword===values.newPassword){
            dispatch(changePasswordFailure('Old password and new password are equal'))
        }else{
            dispatch(changePasswordRequest())
            axios({
                method: 'post',
                url: `${process.env.REACT_APP_API_BASE_URL}/user/password`,
                data: {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                    newPasswordRepeat: values.newPasswordRepeat,
                    email: localStorage.getItem('setUserEmail')
                },
                headers: {
                    'Authorization': `Bearer ${accessToken}`,
                    'Content-Type': 'application/json'
                }
            })
                .then(response => {
                    dispatch(changePasswordSuccess(response.data))
                    dispatch(openChangePasswordForm(false))
                    dispatch(openAlertChangePasswordSuccess(true))
                    dispatch(retryToken(false))
                })
                .catch(error => {
                    console.log(error.response.data.message)
                    
                    console.log(error.response.status)
                    const state = getState()
                    const retryOccurred = state.control.retryToken
                    
                    if(!retryOccurred && error.response.status===401){
                      renewTokenWrapper(dispatch).then(() => {
                        dispatch(changePassword(values))
                      })
                      dispatch(retryToken(true))
                    }else{
                      dispatch(changePasswordFailure(error.response.data.message))
                      dispatch(openAlertChangePasswordFailure(true))
                      if(error.response.status===401){
                        console.log('Need to logout')
                        dispatch(renewTokenExpired(true));
                      }
                    }
                })
        }
    }
}

export const deleteUser = (values) => {
  return (dispatch, getState) => {
    const accessToken = localStorage.getItem('accessToken');

    dispatch(deleteUsersRequest());
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/${values}`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      }
    }).then((response) => {
      
      dispatch(openDeleteUserDialog(false))
      dispatch(deleteUsersSuccess(response.data))
      dispatch(openUserInformation(false))
      dispatch(openAlertDeleteUserSuccess(true))
      dispatch(fetchUsers())
      dispatch(retryToken(false))
    })
    .catch((error) => {
      const errorMessage = error.message;
      
      console.log(error.response.status)
      const state = getState()
      const retryOccurred = state.control.retryToken
      
      if(!retryOccurred && error.response.status===401){
        renewTokenWrapper(dispatch).then(() => {
          dispatch(deleteUser(values))
        })
        dispatch(retryToken(true))
      }else{
        dispatch(deleteUsersFailure(errorMessage))
        if(error.response.status===401){
          console.log('Need to logout')
          dispatch(renewTokenExpired(true));
        }
      }
    });
  }
}

export const fetchRegister = (values) => {
  return (dispatch, getState) => {
    const state = getState();

    const admin = state.register.isAdmin;
    const sizeMultiplier = state.control.setUnit;


    const accessToken = localStorage.getItem('accessToken');

    dispatch(fetchRegisterRequest());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/user/`,
      data: {
        email: values.email,
        firstName: values.firstName,
        lastName: values.lastName,
        companyName: values.companyName,
        admin: admin,
        storageLimit: values.storageLimit*sizeMultiplier
      },
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        
        const register = response.data;
        dispatch(fetchRegisterSuccess(register));
        dispatch(openModalRegister(false));
        dispatch(setAdminRegister(false));
        dispatch(fetchUsers());
        dispatch(openAlertAddUserSuccess(true))
        dispatch(retryToken(false))
      })
      .catch((error) => {
        const errorMessage = error.message;
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(fetchRegister(values))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(fetchRegisterFailure(errorMessage));
          dispatch(setAdminRegister(false));
          dispatch(openAlertAddUserFailure(false))
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};
