import React, {useEffect} from 'react'
import { connect } from 'react-redux'
import { useHistory } from 'react-router-dom'

import FileContainer from './FileContainer'
import AppBarContainer from './AppBarContainer'
import { 
    setLoginState,
    setNavigationTitle
} from '../redux'
import HomePageMenuDrawer from './HomePageDrawerContainer'

const HomePage = ({response, setNavigationTitle}) => {

    const history = useHistory();
    

    
    useEffect(()=>{
        if(response.loggedIn===false || localStorage.getItem('refreshToken')==='' || localStorage.getItem('refreshToken')===null){
            
            history.push('/login')
        }else{
            history.push('/storage/default')
            setNavigationTitle('Storage')
        }
        
    }, [])


    /*
    return (
        <div>
            <HomePageMenuDrawer />
            <div>
                <AppBarContainer />
            </div>
            <br />
            <div>
                <FileContainer />
            </div>
        </div>
    )*/

    return (
        <div>
            <HomePageMenuDrawer />
            <div>
                <AppBarContainer />
            </div>
            <br />
            <div>
                To navigate, click on the menu on the upper left corner of the browser.
            </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        response: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setLoginState: (value) => dispatch(setLoginState(value)),
        setNavigationTitle: (value) => dispatch(setNavigationTitle(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(HomePage)