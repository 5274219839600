import React from 'react';
import { connect } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialIcon from '@material-ui/lab/SpeedDialIcon';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import EditIcon from '@material-ui/icons/Edit';
import {
    Publish, 
    CreateNewFolder
} from '@material-ui/icons'

import {
    openModalCreateFolder,
    openSpeedDial,
    checkOpenFileUploadModal,
    checkOpenCreateFolderModal,
    storeFileUpload
} from '../redux'

const useStyles = makeStyles((theme) => ({
  root: {
    height: 380,
    transform: 'translateZ(0px)',
    flexGrow: 1,
  },
  speedDial: {
    position: 'fixed',
    bottom: theme.spacing(2),
    right: theme.spacing(2),
  },
}));


const FileContainerSpeedDial = ({response, openSpeedDial, checkOpenCreateFolderModal, checkOpenFileUploadModal, storeFileUpload}) => {
  
  const classes = useStyles();


  const handleOpen = () => {
    openSpeedDial(true);
  };

  const handleClose = () => {
    openSpeedDial(false);
  };

  return (
      <SpeedDial
        ariaLabel="SpeedDial openIcon example"
        className={classes.speedDial}
        icon={<SpeedDialIcon openIcon={<EditIcon />} />}
        onClose={handleClose}
        onOpen={handleOpen}
        open={response.speedDialIsOpen}
      >
        <SpeedDialAction 
            key="Upload"
            icon={<Publish />}
            tooltipTitle="Upload"
            onClick={()=>{
              checkOpenFileUploadModal()
              storeFileUpload({})
            }}
        />
        <SpeedDialAction 
            key="Create folder"
            icon={<CreateNewFolder />}
            tooltipTitle="Create folder"
            onClick={()=>checkOpenCreateFolderModal()}
        />
      </SpeedDial>
  );
}

const mapStateToProps = state => {
    return {
        response: state.file
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openModalCreateFolder: (values) => dispatch(openModalCreateFolder(values)),
        openSpeedDial: (values) => dispatch(openSpeedDial(values)),
        checkOpenFileUploadModal: () => dispatch(checkOpenFileUploadModal()),
        checkOpenCreateFolderModal: () => dispatch(checkOpenCreateFolderModal()),
        storeFileUpload: (values) => dispatch(storeFileUpload(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileContainerSpeedDial)