import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { 
    fetchFiles, 
    downloadFile, 
    uploadFile, 
    storeFileUpload, 
    openDeleteFileDialog, 
    openModalCreateFolder,
    openModalSetPermission,
    getPermission,
    setFileName,
    openModalUploadFile,
    toggleProgressBar,
    openAlertFileUploadModalFailure,
    openAlertFileDeleteFailure,
    failureUploadFile,
    openFileInformationModal,
    getFileInformation,
    pauseUploadWhenOffline,
    setNavigationTitle
} from '../redux'
import {
    Grid,
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
    Badge,
    Snackbar
} from '@material-ui/core'
import { 
    Delete, 
    Folder, 
    InsertDriveFile, 
    PersonAdd,
    Autorenew,
    GetApp,
    Info
} from '@material-ui/icons'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import Fab from '@material-ui/core/Fab';
import { size, toArray } from 'lodash';
import MuiAlert from '@material-ui/lab/Alert';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import Tooltip from '@material-ui/core/Tooltip'

import FileInformationModal from './FileContainerFileInformation'
import ConflictFileModal from './FileContainerUploadConflictModal'
import DeleteFileDialogForm from './FileContainerDeleteDialogConfirmation'
import CreateFolderForm from './FileContainerCreateFolderModal'
import SetPermissionModal from './FileContainerSetPermissionModal'
import FileContainerPathBreadcrumb from './FileContainerPathBreadcrumb'
import UploadFileModal from './FileContainerUploadFileModal'
import UploadProgress from './FileContainerUploadProgress'
import FileContainerSpeedDial from './FileContainerSpeedDial'
import Styles from '../css/UploadProgress.module.css'
import AppBarContainer from './AppBarContainer'
import HomePageMenuDrawer from './HomePageDrawerContainer'

const slugify = require('slugify')

const Alert = props => {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
  }

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1000,
        color: '#fff',
    },
    root: {
      flexGrow: 1
    },
    demo: {
      backgroundColor: theme.palette.background.paper,
    },
    title: {
      margin: theme.spacing(4, 0, 2),
    },
    buttonLabel: {
      marginLeft: "5px"
    },
    buttonIcon: {
      justifySelf: "flex-end"
    },
    progressButton: {
      position: 'fixed',
      top: theme.spacing(10),
      right: theme.spacing(2),
      zIndex: 1
    }
  }));

function FileContainer({ login, response , control, fetchFiles, downloadFile, openDeleteFileDialog, openModalSetPermission, getPermission, setFileName, toggleProgressBar, openAlertFileUploadModalFailure, openAlertFileDeleteFailure, failureUploadFile, openFileInformationModal, getFileInformation, pauseUploadWhenOffline, setNavigationTitle }) {

    const classes = useStyles();
    //const { currentPathFolder } = useParams()

    const history = useHistory()
    
    const location = useLocation()

    
    useEffect(()=>{
        
        if(login.loggedIn===false || localStorage.getItem('refreshToken')==='' || localStorage.getItem('refreshToken') === null){
            
            history.push('/login')
        }
        
    }, [])

    useEffect(()=>{
        //fetchFiles(currentPathFolderString.split('|').join('/'))
        let currentPathFolderString = location.pathname

        if(currentPathFolderString == '/storage/default' || currentPathFolderString == '/storage/default/'){
            currentPathFolderString = ''
        }

        //currentPathFolderString = currentPathFolderString.split('_').join(' ');
        currentPathFolderString = decodeURI(currentPathFolderString);
        fetchFiles(currentPathFolderString.replace('/storage/',''))
    }, [location])

    window.addEventListener('offline', (event) => {   
        pauseUploadWhenOffline()
        
    });


    return(
        <div>
            <HomePageMenuDrawer />
        <div>
            <AppBarContainer />
        </div>
        <div>
            <Backdrop className={classes.backdrop} open={response.createFolderInProgress || response.setPermissionInProgress || response.loading}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={control.openAlertFileUploadModalFailure} 
            autoHideDuration={6000} 
            onClose={()=>openAlertFileUploadModalFailure(false)}
            >
                <Alert onClose={()=>openAlertFileUploadModalFailure(false)} severity="error">
                    You don't have the permission to write on this folder.
                </Alert>
            </Snackbar>
            <Snackbar 
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }} 
            open={control.openAlertFileDeleteFailure} 
            autoHideDuration={6000} 
            onClose={()=>openAlertFileDeleteFailure(false)}
            >
                <Alert onClose={()=>openAlertFileDeleteFailure(false)} severity="error">
                    You don't have the permission to delete.
                </Alert>
            </Snackbar>
            <FileInformationModal />
            <DeleteFileDialogForm />
            <FileContainerSpeedDial />
            <SetPermissionModal />
            <CreateFolderForm />
            <UploadFileModal />
            <ConflictFileModal />
            <div>
                <FileContainerPathBreadcrumb />
                {size(toArray(response.fileProgress))>0
                ?<Fab color="primary" className={classes.progressButton} aria-label="add" onClick={()=>toggleProgressBar()}>
                    <Badge badgeContent={size(toArray(response.fileProgress).filter(file=>file.status!=0).filter(file=>file.status!=1).filter(file=>file.status!=5))} color="secondary">
                        <Autorenew fontSize="medium"/>
                    </Badge>
                </Fab>
                :null}
            </div>
            <div className={Styles.divWrapper}>
                <div>
                    {control.progressBarIsOpen?<UploadProgress />:null}
                </div>
                <div>
                    <Grid container justify="flex-start" spacing={2}>
                        <Grid item xs={12} md={11}>
                            <div className={classes.demo}>
                                <List dense={false}>
                                    {response.response.map(file=>
                                        <ListItem button divider>
                                            <ListItemIcon>
                                                {file.type=='folder'?<Folder fontSize="large" style={{fill: "yellow"}} 
                                                onDoubleClick={()=>{
                                                    
                                                    let slugifyPath = `/storage/${response.path}${file.name}/`;
                                                    let slugifyPathArray =  slugifyPath.split('/');
                                                    slugifyPathArray = slugifyPathArray.map(path=>{
                                                        //path = slugify(path,'_');
                                                        path = encodeURI(path);
                                                        return path;
                                                    });
                                                    slugifyPath = slugifyPathArray.join('/');
                                                    history.push(slugifyPath);
                                                }} />:<InsertDriveFile fontSize="large" style={{fill: "primary"}} onDoubleClick={()=>downloadFile(`${response.path}${file.name}`)}/>}
                                            </ListItemIcon>
                                            <ListItemText
                                                onDoubleClick={()=>{
                                                    if(file.type==='folder'){
                                                        
                                                        let slugifyPath = `/storage/${response.path}${file.name}/`;
                                                        let slugifyPathArray =  slugifyPath.split('/');
                                                        slugifyPathArray = slugifyPathArray.map(path=>{
                                                            //path = slugify(path,'_');
                                                            path = encodeURI(path)
                                                            return path;
                                                        });
                                                        slugifyPath = slugifyPathArray.join('/');
                                                        history.push(slugifyPath);
                                                        
                                                    }
                                                    else{
                                                        downloadFile(`${response.path}${file.name}`)
                                                    }
                                                }}
                                                primary={file.name}
                                            />
                                            <Tooltip title="Download">
                                                <IconButton color="inherit" 
                                                    onClick={()=>{
                                                        {file.type=='folder'?
                                                        downloadFile(`${response.path}${file.name}/`)
                                                        : downloadFile(`${response.path}${file.name}`)
                                                        }
                                                    }
                                                }>
                                                    <GetApp />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Delete">
                                                <IconButton color="inherit"
                                                onClick={()=>{
                                                    {file.type=='folder'
                                                    ?setFileName(`${file.name}/`)
                                                    :setFileName(`${file.name}`)}
                                                    openDeleteFileDialog(true)
                                                }}>
                                                    <Delete />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Share">
                                                <IconButton color="inherit"
                                                onClick={()=>{
                                                    openModalSetPermission(true)
                                                    {file.type=='folder'?
                                                    getPermission(`${response.path}${file.name}/`)
                                                    : getPermission(`${response.path}${file.name}`)
                                                    }
                                                    {file.type=='folder'?
                                                    setFileName(`${file.name}/`)
                                                    : setFileName(`${file.name}`)
                                                    }
                                                }}>
                                                    <PersonAdd />
                                                </IconButton>
                                            </Tooltip>
                                            <Tooltip title="Information">
                                                <IconButton color="inherit"
                                                onClick={()=>{
                                                    {file.type=='folder'?
                                                    getFileInformation(`${response.path}${file.name}/`)
                                                    : getFileInformation(`${response.path}${file.name}`)
                                                    }
                                                }}>
                                                    <Info />
                                                </IconButton>
                                            </Tooltip>
                                        </ListItem>
                                    )}
                                </List>
                            </div>
                        </Grid>
                    </Grid>
                </div>
            </div>
        </div>
        </div>
    )
}

const mapStateToProps = state => {
    return {
        response: state.file,
        control: state.control,
        login: state.login
    }
}

const mapDispatchToProps = dispatch => {
    return {
        fetchFiles: (values) => dispatch(fetchFiles(values)),
        downloadFile: (values) => dispatch(downloadFile(values)),
        uploadFile: (file,path) => dispatch(uploadFile(file,path)),
        storeFileUpload: (values) => dispatch(storeFileUpload(values)),
        openDeleteFileDialog: (values) => dispatch(openDeleteFileDialog(values)),
        openModalCreateFolder: (values) => dispatch(openModalCreateFolder(values)),
        openModalSetPermission: (values) => dispatch(openModalSetPermission(values)),
        getPermission: (values) => dispatch(getPermission(values)),
        setFileName: (values) => dispatch(setFileName(values)),
        openModalUploadFile: (values) => dispatch(openModalUploadFile(values)),
        toggleProgressBar: () => dispatch(toggleProgressBar()),
        openAlertFileUploadModalFailure: (value) => dispatch(openAlertFileUploadModalFailure(value)),
        openAlertFileDeleteFailure: (value) => dispatch(openAlertFileDeleteFailure(value)),
        failureUploadFile: (value) => dispatch(failureUploadFile(value)),
        openFileInformationModal: (value) => dispatch(openFileInformationModal(value)),
        getFileInformation: (value) => dispatch(getFileInformation(value)),
        pauseUploadWhenOffline: () => dispatch(pauseUploadWhenOffline()),
        setNavigationTitle: (value) => dispatch(setNavigationTitle(value))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileContainer)