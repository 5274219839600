import axios from 'axios';
import { size, toArray } from 'lodash';

import {
  FETCH_FILE_REQUEST,
  FETCH_FILE_SUCCESS,
  FETCH_FILE_FAILURE,
  STORE_FILE_UPLOAD,
  UPLOAD_FILE_REQUEST,
  UPLOAD_FILE_SUCCESS,
  UPLOAD_FILE_FAILURE,
  DELETE_FILE_FAILURE,
  DELETE_FILE_REQUEST,
  DELETE_FILE_SUCCESS,
  OPEN_MODAL_CREATE_FOLDER,
  CREATE_FOLDER_FAILURE,
  CREATE_FOLDER_REQUEST,
  CREATE_FOLDER_SUCCESS,
  SET_PERMISSION_FAILURE,
  SET_PERMISSION_REQUEST,
  SET_PERMISSION_SUCCESS,
  REFRESH_PERMISSION_ERROR,
  OPEN_MODAL_SET_PERMISSION,
  GET_PERMISSION_FAILURE,
  GET_PERMISSION_REQUEST,
  GET_PERMISSION_SUCCESS,
  OPEN_FORM_SET_PERMISSION,
  SET_ADMIN,
  SET_READ,
  SET_DELETE,
  SET_WRITE,
  SET_PERMISSION_DEFAULT_EMAIL,
  ADD_USER,
  SET_FILE_NAME,
  OPEN_MODAL_UPLOAD_FILE,
  SET_UPLOAD_FILE,
  SET_UPLOAD_PROGRESS,
  SUCCESS_UPLOAD_FILE,
  FAILURE_UPLOAD_FILE,
  SET_UPLOAD_ID,
  SPEED_DIAL_OPEN,
  SET_UPLOAD_STATUS,
  SET_RESUME_PARAMS,
  SET_CURRENT_UPLOAD_SIZE,
  SET_CONFLICT_FILES,
  SET_APPLY_TO_ALL,
  GET_FILE_INFORMATION_FAILURE,
  GET_FILE_INFORMATION_REQUEST,
  GET_FILE_INFORMATION_SUCCESS,
  GET_OWNER_FILES_REQUEST,
  GET_OWNER_FILES_FAILURE,
  GET_OWNER_FILES_SUCCESS,
  DELETE_PERMISSION_REQUEST,
  DELETE_PERMISSION_SUCCESS,
  DELETE_PERMISSION_FAILURE,
  ABORT_UPLOAD_REQUEST,
  ABORT_UPLOAD_FAILURE,
  ABORT_UPLOAD_SUCCESS,
  PART_UPLOAD_COMPLETE,
  FILE_RESET,
  CHECK_FOLDER_EXIST,
} from './fileTypes';

import { 
  openAlertFileUploadModalFailure, 
  openDeleteFileDialog, 
  openAlertFileDeleteFailure, 
  openFileInformationModal,
  retryToken,
  renewTokenExpired
} from '../controls/controlActions';

import { 
  renewToken
} from '../login/loginActions';

//const accessToken = localStorage.getItem('accessToken')
const refreshToken = localStorage.getItem('refreshToken');


/********** AWS CONFIG ************/
const aws = require('aws-sdk');

const s3 = new aws.S3({
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  apiVersion: '2006-03-01',
  maxRetries: 1
});
const bucket = process.env.REACT_APP_BUCKET;


/**********************************/

const s3Stream = require('s3-upload-stream')(s3) // s3 stream upload

/*
export const setInternetStatus = (value) => {
  return {
    type: SET_INTERNET_STATUS,
    payload: value
  }
}*/

/********** AWS CONFIG & ZIP STREAM ************/

const config = {
  accessKeyId: process.env.REACT_APP_AWS_ACCESS_KEY,
  secretAccessKey: process.env.REACT_APP_AWS_SECRET_ACCESS_KEY,
  bucket: bucket
}

//const s3Zipper = require('s3-zip-stream')
//var zipper = new s3Zipper(config);

/**********************************/

const renewTokenWrapper = (dispatch) => new Promise((resolve,reject) => {
  dispatch(renewToken(resolve))
})

const uploadCompleteWrapper = (id,dispatch,getState) => {
  
  const state = getState()
  const accessToken = localStorage.getItem('accessToken');

  axios({
    baseURL: process.env.REACT_APP_API_BASE_URL,
    url: '/storage/complete-upload',
    method: 'post',
    data: {
      fileName: state.file.fileProgress[id].file.name,
      fileType: state.file.fileProgress[id].file.type
    },
    headers: {
      Authorization: `Bearer ${accessToken}`,
      path: state.file.fileProgress[id].path,
      fileSize: state.file.fileProgress[id].file.size
    },
  }).then(completeUploadResp=>{
    const state = getState();
    dispatch(successUploadFile(id));
    dispatch(fetchFiles(state.file.path));
    dispatch(retryToken(false))
  }).catch(error => {
    
    const errorMessage = error.message;
    const state = getState()
    const retryOccurred = state.control.retryToken
    
    if(!retryOccurred && error.response.status===401){
      renewTokenWrapper(dispatch).then(() => {
        uploadCompleteWrapper(id,dispatch,getState)
      })
      dispatch(retryToken(true))
    }else{
      dispatch(failureUploadFile(id));
      if(error.response.status===401){
        dispatch(renewTokenExpired(true));
      }
    }
  })
}

export const conflictKeepBothFiles = () => {
  return (dispatch, getState) => {
    const state = getState()
    const applyToAll = state.file.applyToAll
    const path = state.file.path
    let uploadFiles = state.file.file
    let conflictFilesResultArray = state.file.conflictFiles
    let uploadPath = path
    let uploadFilesInArray = Object.keys(uploadFiles).map((key) => uploadFiles[key]);
    let d = new Date()

    if(!(path.startsWith('Shared by other Users/') || path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
    
      uploadPath=`Shared by other Users/${localStorage.getItem('email')}/${path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`
  
    }

    if(applyToAll){
      conflictFilesResultArray.map((file,index)=>{
        let objectIndex = uploadFilesInArray.findIndex(x=>x.name===conflictFilesResultArray[index])
        //FORMING FILE NAME
        let fileNameArray = []
        let fileName = ''
        let fileExt = ''
        if(conflictFilesResultArray[index].split('.').length>1){
          fileNameArray = conflictFilesResultArray[index].split('.')
          fileExt = fileNameArray.pop()
          fileName = fileNameArray.join('.')
          fileName = `${fileName}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}.${fileExt}`
        }else{
          fileName = `${conflictFilesResultArray[index]}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}`
        }

        /*
        Object.defineProperty(uploadFiles[objectIndex], 'name', {
          writeable: true,
          value: `${uploadFiles[objectIndex].name}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}`
        });
        */
        Object.defineProperty(uploadFiles[objectIndex], 'name', {
          writeable: true,
          value: fileName
        });
      })
      dispatch(storeFileUpload(uploadFiles))
      dispatch(setConflictFiles([]))

      ////////////////////////////////////
      let filesArray = Object.values(uploadFiles)
      if(filesArray[0].webkitRelativePath!='' && filesArray[0].webkitRelativePath!=null){
        let listOfFilesWithFolder = getListOfFilesWithFolders(filesArray,state)
        let listOfFoldersToBeCreated = getListOfFoldersToBeCreated(listOfFilesWithFolder)
        console.log('*********** LIST OF FOLDERS TO BE CREATED')
        console.log(listOfFoldersToBeCreated)
        createFoldersDuringFolderUpload(listOfFoldersToBeCreated, state, (result)=>{ 
          console.log('#################### DONE CREATING ALL FOLDER ##########################')
          console.log(result)
          dispatch(setUploadFile(uploadFiles,uploadPath))
        })
      }else{
        dispatch(setUploadFile(uploadFiles,uploadPath))
      }

      ////////////////////////////////////


      //dispatch(setUploadFile(uploadFiles,uploadPath))
    }else{
      let objectIndex = uploadFilesInArray.findIndex(x=>x.name===conflictFilesResultArray[0])

      //FORMING FILE NAME
      let fileNameArray = []
      let fileName = ''
      let fileExt = ''
      if(conflictFilesResultArray[0].split('.').length>1){
        fileNameArray = conflictFilesResultArray[0].split('.')
        fileExt = fileNameArray.pop()
        fileName = fileNameArray.join('.')
        fileName = `${fileName}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}.${fileExt}`
      }else{
        fileName = `${conflictFilesResultArray[0]}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}`
      }

      /*
      Object.defineProperty(uploadFiles[objectIndex], 'name', {
        writable: true,
        value: `${uploadFiles[objectIndex].name}_${d.getFullYear()}-${d.getMonth()+1}-${d.getDate()}-${d.getHours()}${d.getMinutes()}${d.getSeconds()}`
      });
      */
      Object.defineProperty(uploadFiles[objectIndex], 'name', {
        writable: true,
        value: fileName
      });
      dispatch(storeFileUpload(uploadFiles))
      conflictFilesResultArray.shift()
      dispatch(setConflictFiles(conflictFilesResultArray))
      if(conflictFilesResultArray.length===0){
        ////////////////////////////////////
        let filesArray = Object.values(uploadFiles)
        if(filesArray[0].webkitRelativePath!='' && filesArray[0].webkitRelativePath!=null){
          let listOfFilesWithFolder = getListOfFilesWithFolders(filesArray,state)
          let listOfFoldersToBeCreated = getListOfFoldersToBeCreated(listOfFilesWithFolder)
          console.log('*********** LIST OF FOLDERS TO BE CREATED')
          console.log(listOfFoldersToBeCreated)
          createFoldersDuringFolderUpload(listOfFoldersToBeCreated, state, (result)=>{ 
            console.log('#################### DONE CREATING ALL FOLDER ##########################')
            console.log(result)
            dispatch(setUploadFile(uploadFiles,uploadPath))
          })
        }else{
          dispatch(setUploadFile(uploadFiles,uploadPath))
        }

        ////////////////////////////////////
        //dispatch(setUploadFile(uploadFiles,uploadPath))
      }
    }
  }
}

export const conflictOverwrite = () => {
  return (dispatch, getState) => {
    const state = getState()
    const applyToAll = state.file.applyToAll
    const path = state.file.path
    let uploadFiles = state.file.file
    let conflictFilesResultArray = state.file.conflictFiles
    let uploadPath = path

  

    if(!(path.startsWith('Shared by other Users/') || path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
    
      uploadPath=`Shared by other Users/${localStorage.getItem('email')}/${path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`
  
    }

    

    
    if(applyToAll){
      dispatch(setConflictFiles([]))
      ////////////////////////////////////
      let filesArray = Object.values(uploadFiles)
      if(filesArray[0].webkitRelativePath!='' && filesArray[0].webkitRelativePath!=null){
        let listOfFilesWithFolder = getListOfFilesWithFolders(filesArray,state)
        let listOfFoldersToBeCreated = getListOfFoldersToBeCreated(listOfFilesWithFolder)
        console.log('*********** LIST OF FOLDERS TO BE CREATED')
        console.log(listOfFoldersToBeCreated)
        createFoldersDuringFolderUpload(listOfFoldersToBeCreated, state, (result)=>{ 
          console.log('#################### DONE CREATING ALL FOLDER ##########################')
          console.log(result)
          dispatch(setUploadFile(uploadFiles,uploadPath))
        })
      }else{
        dispatch(setUploadFile(uploadFiles,uploadPath))
      }

      ////////////////////////////////////
      //dispatch(setUploadFile(uploadFiles,uploadPath))
    }else{
      conflictFilesResultArray.shift()
      dispatch(setConflictFiles(conflictFilesResultArray))
      if(conflictFilesResultArray.length===0){
        ////////////////////////////////////
        let filesArray = Object.values(uploadFiles)
        if(filesArray[0].webkitRelativePath!='' && filesArray[0].webkitRelativePath!=null){
          let listOfFilesWithFolder = getListOfFilesWithFolders(filesArray,state)
          let listOfFoldersToBeCreated = getListOfFoldersToBeCreated(listOfFilesWithFolder)
          console.log('*********** LIST OF FOLDERS TO BE CREATED')
          console.log(listOfFoldersToBeCreated)
          createFoldersDuringFolderUpload(listOfFoldersToBeCreated, state, (result)=>{ 
            console.log('#################### DONE CREATING ALL FOLDER ##########################')
            console.log(result)
            dispatch(setUploadFile(uploadFiles,uploadPath))
          })
        }else{
          dispatch(setUploadFile(uploadFiles,uploadPath))
        }

        ////////////////////////////////////
        //dispatch(setUploadFile(uploadFiles,uploadPath))
      }
    }
  }
}

export const getFileInformation = (file) => {

  const accessToken = localStorage.getItem('accessToken');
  
  return (dispatch, getState) => {
    const state = getState();

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/files`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: file,
      },
    }).then(listfiles => {
      
      let arrayFileSize = listfiles.data.objects.map(file => file.fileSize);
      let fileSize = arrayFileSize.reduce((a, b) => parseInt(a) + parseInt(b), 0)
      
      
      if(file[file.length-1]==='/'){
        dispatch(getFileInformationSuccess({fileSize:fileSize,fileType:'folder'}))
      }else{
        dispatch(getFileInformationSuccess({fileSize:fileSize,fileType:listfiles.data.objects[0].type}))
      } 
      dispatch(openFileInformationModal(true))
      dispatch(retryToken(false))
    }).catch((error) => {
        
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(getFileInformation(file))
          })
          dispatch(retryToken(true))
        }else{
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
    });
  }
}

const fileFolderFilter = (file) => {
  return file.type=='folder'
}

export const checkFolderExist = (newFolder, currentFiles) => {
  console.log('new folder')
  console.log(newFolder)
  console.log('original list of files')
  console.log(currentFiles)
  let checkFolderExistIndicator = 0
  let filteredResult = currentFiles.filter(fileFolderFilter);
  console.log('filtered list of files')
  console.log(filteredResult)
  let result = filteredResult.map(a => a.name);
  console.log('trimmed list of file')
  console.log(result)

  result.forEach(name=>{
    if(newFolder==name){
      checkFolderExistIndicator=1;
    }
  })
  
  console.log(checkFolderExistIndicator)

  return {
    type: CHECK_FOLDER_EXIST,
    payload: checkFolderExistIndicator
  }
}

export const setFolderExistIndicator = (value) => {
  return {
    type: CHECK_FOLDER_EXIST,
    payload: value
  }
}

export const partUploadComplete = (id, value) => {
  return {
    type: PART_UPLOAD_COMPLETE,
    payload: {
      id,
      value
    }
  }
}

export const fileReset = () => {
  return {
    type: FILE_RESET
  }
}

export const abortUploadRequest = () => {
  return {
    type: ABORT_UPLOAD_REQUEST
  }
}

export const abortUploadSuccess = value => {
  return {
    type: ABORT_UPLOAD_SUCCESS,
    payload: value
  }
}

export const abortUploadFailure = value => {
  return {
    type: ABORT_UPLOAD_FAILURE,
    payload: value
  }
}

export const pauseUploadWhenOffline = () => {
  return (dispatch, getState) => {
    const state = getState();
    

    const fileToUpload = toArray(state.file.fileProgress).filter(file=>file.status===3)
        fileToUpload.map(file=>{
            
            dispatch(failureUploadFile(file.id))
        })
  }
}

export const abortUpload = value => {

  

  const id = value;
  
  return (dispatch, getState) => {
    dispatch(setUploadStatus(id,4))
  };
}

export const abortUploadInS3 = value => {
  
  const id = value;
  return (dispatch, getState) => {

    const state = getState();
    
    const params = {
      Bucket: bucket, 
      Key: `${state.file.fileProgress[id].path}${state.file.fileProgress[id].file.name}`,
      UploadId: state.file.fileProgress[id].uploadID
     };
     s3.abortMultipartUpload(params, (err, data) => {
       if (err){
        console.log(err, err.stack); // an error occurred
       } 
       else {
                   // successful response
        dispatch(setUploadStatus(id,5))
       }    
       
     });
  }
}


export const deletePermissionRequest = () => {
  return {
    type: DELETE_PERMISSION_REQUEST
  }
}

export const deletePermissionSuccess = value => {
  return {
    type: DELETE_PERMISSION_SUCCESS,
    payload: value
  }
}

export const deletePermissionFailure = value => {
  return {
    type: DELETE_PERMISSION_FAILURE,
    payload: value
  }
}

export const deletePermission = value => {
  const accessToken = localStorage.getItem('accessToken');
  
  return (dispatch, getState) => {

    const state = getState();
    const path = `${state.file.path}${state.file.fileName}`;
    const email = state.file.permissionDefaultEmail;


    dispatch(deletePermissionRequest());
    axios({
      method: 'delete',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/permission`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: path,
        email: email
      },
    })
      .then((response) => {
        dispatch(openFormSetPermission(false))
        dispatch(getPermission(path))
        
        dispatch(retryToken(false))
      })
      .catch((error) => {
        
        
        
        const errorMessage = error.message;
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(deletePermission(value))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(getOwnerFilesFailure(error))
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
}

export const getOwnerFilesFailure = value => {
  return {
    type: GET_OWNER_FILES_FAILURE,
    payload: value
  }
}

export const getOwnerFilesSuccess = value => {
  return {
    type: GET_OWNER_FILES_SUCCESS,
    payload: value
  }
}

export const getOwnerFilesRequest = value => {
  return {
    type: GET_OWNER_FILES_REQUEST
  }
}

export const getOwnerFiles = () => {
  const accessToken = localStorage.getItem('accessToken');
  
  return (dispatch, getState) => {

    const state = getState();
    const filesToUpload = state.file.file;

    dispatch(getOwnerFilesRequest());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/ownerfiles`,
      headers: {
        Authorization: `Bearer ${accessToken}`
      },
    })
      .then((response) => {
        dispatch(retryToken(false))
        axios({
          method: 'get',
          url: `${process.env.REACT_APP_API_BASE_URL}/user/userinformation`,
          headers: {
            Authorization: `Bearer ${accessToken}`
          }
        }).then(userinfo=>{
          dispatch(retryToken(false))
          const filesToUploadSize = Object.values(filesToUpload).map(file=>file.size).reduce((a, b) => a + b, 0)
          
          const currentFileSize = response.data.map(file=>Number(file.fileSize)).reduce((a, b) => a + b, 0)
          
          const sizeLimit = Number(userinfo.data.storageLimit)
          
          if((filesToUploadSize+currentFileSize)>sizeLimit){
            
            dispatch(getOwnerFilesSuccess(false))
          }else{
            
            dispatch(getOwnerFilesSuccess(true))
          }
        }).catch(error=>{
          console.log(error.response.status)
          const state = getState()
          const retryOccurred = state.control.retryToken
          
          if(!retryOccurred && error.response.status===401){
            renewTokenWrapper(dispatch).then(() => {
              dispatch(getOwnerFiles())
            })
            dispatch(retryToken(true))
          }else{
            dispatch(getOwnerFilesFailure(error))
            if(error.response.status===401){
              console.log('Need to logout')
              dispatch(renewTokenExpired(true));
            }
          }
        })
      })
      .catch((error) => {
        
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(getOwnerFiles())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(getOwnerFilesFailure(error))
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const getFileInformationRequest = () => {
  return {
    type: GET_FILE_INFORMATION_REQUEST
  }
}

export const getFileInformationSuccess = (value) => {
  return {
    type: GET_FILE_INFORMATION_SUCCESS,
    payload: value
  }
}

export const getFileInformationFailure = (value) => {
  return {
    type: GET_FILE_INFORMATION_FAILURE,
    payload: value
  }
}

export const setApplyToAll = (value) => {
  return {
    type: SET_APPLY_TO_ALL,
    payload: value
  }
}

export const setConflictFiles = (files) => {
  return {
    type: SET_CONFLICT_FILES,
    payload: files
  }
}


export const setCurrentUploadSize = (id, uploadSize, params) => {
  return {
    type: SET_CURRENT_UPLOAD_SIZE,
    payload: {
      id,
      uploadSize,
      params
    }
  }
}

export const setResumeParams = (id, resumeParams, status) => {
  return {
    type: SET_RESUME_PARAMS,
    payload: {
      id,
      resumeParams,
      status
    }
  }
}

export const openSpeedDial = (value) => {
  return {
    type: SPEED_DIAL_OPEN,
    payload: value,
  };
};

export const setUploadID = (id, uploadID) => {
  return {
    type: SET_UPLOAD_ID,
    payload: {
      id,
      uploadID
    }
  };
};

export const setUploadStatus = (id, status) => {

  
  return {
    type: SET_UPLOAD_STATUS,
    payload: {
      id,
      status
    }
  }
}

export const setUploadProgress = (id, progress) => {
  return {
    type: SET_UPLOAD_PROGRESS,
    payload: {
      id,
      progress,
    },
  };
};

export const successUploadFile = (id) => {
  return {
    type: SUCCESS_UPLOAD_FILE,
    payload: id,
  };
};

export const failureUploadFile = (id) => {
  return {
    type: FAILURE_UPLOAD_FILE,
    payload: id,
  };
};

const getListOfFilesWithFolders = (files, state) => {

  let listOfFilesWithFolder = [];
  //console.log('Start with getting list of files with folders')

  if(files.length){
    //console.log('files.length ok')
    //console.log(files)
    files.forEach(async (file) => {
      //console.log('went inside forEach')
      try{
        //console.log('went here')
        /*if(state.file.fileProgress[file.id].file.webkitRelativePath!='' && state.file.fileProgress[file.id].file.webkitRelativePath!=null){
      
          listOfFilesWithFolder.push(state.file.fileProgress[file.id].file.webkitRelativePath);
        
        }*/
        if(file.webkitRelativePath!='' && file.webkitRelativePath!=null){
          listOfFilesWithFolder.push(file.webkitRelativePath)
        }
        //console.log('went here after')
      }catch(error){
        console.log(error)
      }
    })
  }

  return listOfFilesWithFolder;
}

const getListOfFoldersToBeCreated = (files) => {

  let listOfFoldersToBeCreated = [];
  let uniqueListOfFoldersToBeCreated;
  let uniqueListWithCount = [];

  files.forEach(async (file) => {
    //console.log('222222222 ALL FILES')
    //console.log(file)
    let result=0;
    try{
      while(result!=-1){
        result = file.indexOf('/',result+1);
        console.log(result)
        if(result!=-1){
          //console.log('!!!!!!!!!! TO BE PUSHED TO ARRAY')
          //console.log(file.slice(0,result+1))
          listOfFoldersToBeCreated.push(file.slice(0,result+1));
        }
      }
      
    }catch(error){
      console.log(error);
    }
  })

  uniqueListOfFoldersToBeCreated = new Set(listOfFoldersToBeCreated)
  //console.log('1111111111111 UNIQUE LIST')
  //console.log(uniqueListOfFoldersToBeCreated)
  listOfFoldersToBeCreated = [...uniqueListOfFoldersToBeCreated];

  listOfFoldersToBeCreated.forEach( async (file) => {
    
    let result=0;
    let counter=0;
    //console.log(str);

    try{
      while(counter<(file.split('/').length - 2)){
        //if(result==0){
          result = file.indexOf('/',result);
        /*}else{
          result = file.indexOf('/',result+1);
        }*/
        result++
        //console.log(result)
        counter++;
      }
    }catch(error){
      console.log('Get folder list')
      console.log(error);
    }

    uniqueListWithCount.push({"name":file,"order":file.split('/').length - 1,"pathEnd":result}
    )
  })

  uniqueListWithCount.sort((a, b) => (a.order > b.order) ? 1 : -1)

  return uniqueListWithCount;
  
}

const createFoldersDuringFolderUpload =  async (listOfFoldersToBeCreated, state, callback) => {

  const accessToken = localStorage.getItem('accessToken');
  let createAllFolderArray = [];
  let createFolderPromise;
  let createFolderAwait;

  for(let folderCounter=0;folderCounter<listOfFoldersToBeCreated.length;folderCounter++){

    createFolderPromise = new Promise((resolve, reject) => {          

      const folderPath = state.file.path + listOfFoldersToBeCreated[folderCounter].name.slice(0,listOfFoldersToBeCreated[folderCounter].pathEnd);
      const folder = listOfFoldersToBeCreated[folderCounter].name.slice(listOfFoldersToBeCreated[folderCounter].pathEnd,listOfFoldersToBeCreated[folderCounter].name.length+1);

      console.log('PATH OF THE FOLDER TO BE CREATED >>>>>>>>>>>>>>>>')
      console.log(folderPath)

      console.log('NAME OF THE FOLDER TO BE CREATED >>>>>>>>>>>')
      console.log(folder)

      axios({
        method: 'get',
        url: `${process.env.REACT_APP_API_BASE_URL}/storage/file`,
        headers: {
          Authorization: `Bearer ${accessToken}`,
          fileName: `${folderPath}${folder}`
        }
      }).then((response)=>{
        console.log('Response after checking if folder exists')
        console.log(response.data)
        if(response.data.length==0){
          console.log('Proceed with creating folder')
          axios({
            method: 'post',
            url: `${process.env.REACT_APP_API_BASE_URL}/storage/folder`,
            headers: {
              Authorization: `Bearer ${accessToken}`,
              path: folderPath,
              'Content-Type': 'application/json',
            },
            data: {
              folder: `${folder}`,
            },
          }).then((response) => {
            console.log('SUCCESSFUL CREATION OF FOLDER ************************')
            //dispatch(createFolderSuccess(response));
            //dispatch(openModalCreateFolder(false));
            //dispatch(retryToken(false))
            resolve(response)
            createAllFolderArray.push(response)
            if(listOfFoldersToBeCreated.length==createAllFolderArray.length){
              callback(1);
            }
          })
          .catch((error) => {
            console.log(error.response.status)
            //state = getState()
            const retryOccurred = state.control.retryToken
            
            /*
            if(!retryOccurred && error.response.status===401){
              renewTokenWrapper(dispatch).then(() => {
                //dispatch(uploadFile(files))
              })
              dispatch(retryToken(true))
            }else{
              dispatch(createFolderFailure(error.response.data.message));
              if(error.response.status===401){
                console.log('Need to logout')
                dispatch(renewTokenExpired(true));
              }
            }*/
            resolve(error)
            createAllFolderArray.push(response)
            if(listOfFoldersToBeCreated.length==createAllFolderArray.length){
              callback(0);
            }
          });
        }else{
          resolve(response)
          createAllFolderArray.push(response)
          if(listOfFoldersToBeCreated.length==createAllFolderArray.length){
            callback(1);
          }
        }
      })
      
      
    });

    createFolderAwait = await createFolderPromise
    
  }

}


export const uploadFile = (files) => (dispatch, getState) => {

  const accessToken = localStorage.getItem('accessToken');
  let state = getState();
  const CHUNK_SIZE = 6000000;
  let listOfFilesWithFolder = [];
  let listOfFoldersToBeCreated = [];
  let createFolderPromise;
  let createFolderAwait;
  let createAllFolderPromise;
  let createAllFolderAwait;
  let createAllFolderArray = [];
  let asyncArray = ['array'];

  if (files.length) {

    
    files.forEach(async (file) => {

      try {

        dispatch(setUploadStatus(file.id,3))
        let concurrency = Number(process.env.REACT_APP_INITIAL_UPLOAD_CONCURRENCY);
        
        const uploadPath = state.file.fileProgress[file.id].path
        let fileSize = state.file.fileProgress[file.id].file.size;
        let CHUNKS_COUNT = Math.floor(fileSize / CHUNK_SIZE) + 1;
        const fileType = state.file.fileProgress[file.id].file.type;

        

        let fileName = state.file.fileProgress[file.id].file.name;
        
        console.log('Upload path and filename')
        console.log(uploadPath)
        console.log(fileName)

        let start, end, blob, startTime, endTime;
        let uploadID = state.file.fileProgress[file.id].uploadID;
        let createMultipartPromise;
        let partUploadPromise;
        let partResults;
        const chunkArray = Array.from({length: CHUNKS_COUNT}, (_, index) => index + 1) //Created an array from 1 to chunk count
        let innerArray = []
        let partsArray = []
        let indexOffset= 0
        let uploadTasks = []
        let successfulUploadTasks = []
        let currentUploadArray = []
        let currentTotalTime
        //let partUploadComplete = 0
        let partsArrayLengthSentinel = 0
        let samePartsLengthCounter = 0

        

        partsArray = chunkArray /////added must be removed if using the fixed array length

        if(!state.file.fileProgress[file.id].params){

        }else{
          //Getting the part numbers uploaded and removing them from "parts array (1-chunk count)"
          let statePartsArray = state.file.fileProgress[file.id].params.map(a => a.PartNumber);
          partsArray = partsArray.filter(n => !statePartsArray.includes(n))
          
        }

        

        const createMultipartUploadParams = {
          Bucket: bucket,
          Key: `${uploadPath}${fileName}`,
          ContentType: fileType,
          ContentDisposition: `attachment; filename="${fileName}"`
        };
        
        if(!uploadID){
          // if this is the first time of upload, create a multipart upload
          createMultipartPromise = new Promise((resolve, reject) => {
            s3.createMultipartUpload(createMultipartUploadParams, (err, uploadData) => {
              if(err){
                console.log(err)
              }else{
                dispatch(setUploadID(file.id,uploadData.UploadId))
                resolve(uploadData.UploadId)
              }
            })
          });   
        }else{
          // if upload ID already exists
          createMultipartPromise = new Promise((resolve, reject) => {
            resolve(uploadID)
          })
        }
        

        uploadID = await createMultipartPromise


        //Loop thru all the elements of parts array (1 to chunk count)

        while(partsArray.length>0){

          

          state=getState()
          

          // Check if need to pause upload
          if(state.file.fileProgress[file.id].status===4 || state.file.fileProgress[file.id].status===2){
            if(state.file.fileProgress[file.id].status===2){
              //console.log('>>>>>>>>>>> SELF-HEALING. RETRY UPLOAD')
              dispatch(setUploadStatus(file.id,0))
            }
            if(state.file.fileProgress[file.id].status===4){
              dispatch(abortUploadInS3(file.id))
            }
            break;
          } 
          
          partUploadPromise = new Promise(async (resolve, reject) => {
            
            
            //get list of part number array based on concurrency
            currentUploadArray = partsArray.slice(0,concurrency)
            
            
            currentUploadArray.map(async partNumber=>{
            
              startTime = (new Date()).getTime();

              //getting the byte to upload
              start = (partNumber - 1) * CHUNK_SIZE;
              end = partNumber * CHUNK_SIZE;
              blob =
                partNumber < CHUNKS_COUNT
                  ? file.file.slice(start, end)
                  : file.file.slice(start);

              const params = {
                Bucket: bucket,
                Key: `${uploadPath}${fileName}`,
                PartNumber: partNumber,
                UploadId: uploadID,
                Body: blob
              }

              //Check if part number already exists before uploading (to avoid duplicate)
              
              state=getState()
              let checkParamsBeforePartUpload = state.file.fileProgress[file.id].params
              if(!checkParamsBeforePartUpload){
                checkParamsBeforePartUpload = []
              }
              let checkIndexPartNumberBeforePartUpload = checkParamsBeforePartUpload.findIndex(x => x.PartNumber === partNumber);

              //Skip upload if part number already exists or file done uploading
              if(checkIndexPartNumberBeforePartUpload>=0 || state.file.fileProgress[file.id].status===1){
                
                let offset = state.file.fileProgress[file.id].uploadedSize
                let currentUploadSize = offset
                uploadTasks.push(partNumber) //Resolve promise to finish concurrent upload
                successfulUploadTasks.push(partNumber) //Add the part number to successful list of part numbers
                // Check if this is the last file to be uploaded on the current iteration
                
                if(uploadTasks.length===concurrency || currentUploadSize===fileSize){
                  resolve(uploadTasks)
                  if(currentUploadSize===fileSize){
                    dispatch(partUploadComplete(file.id,1))
                    //partUploadComplete = 1;
                  }
                }

              }else{

                //Upload part of file based on part number
                  s3.uploadPart(params, (err, data) => {
                    if(err){
                      console.log(err)
                      let offset = state.file.fileProgress[file.id].uploadedSize
                      let currentUploadSize = offset
                      uploadTasks.push(partNumber)
                      dispatch(failureUploadFile(file.id));
                      
                      if(uploadTasks.length===concurrency || currentUploadSize===fileSize){
                        resolve(uploadTasks)
                        //Record total time for the last concurrent upload to determine next concurrency
                        currentTotalTime = (endTime-startTime)/1000
                        if(currentUploadSize===fileSize){
                          dispatch(partUploadComplete(file.id,1))
                          //partUploadComplete = 1;
                        }
                      }
                    }
                    else{
                      //Part upload successful
                      endTime = (new Date()).getTime();
                      uploadTasks.push(partNumber)
                      successfulUploadTasks.push(partNumber)
                      state=getState()

                      let offset = state.file.fileProgress[file.id].uploadedSize
                      if(!offset){
                        offset = 0
                      }

                      //setting up the current upload size, 
                      let currentUploadSize = offset + CHUNK_SIZE
                      if(currentUploadSize>fileSize){
                        currentUploadSize = fileSize
                      }
                      
                      //Check if upload number already exists
                      let params = state.file.fileProgress[file.id].params
                      if(!params){
                        params = []
                      }
                      let indexPartNumber = params.findIndex(x => x.PartNumber === partNumber);
                      if(indexPartNumber>=0){
                        //if yes, replace the parameter of a specific part number with the new one
                        currentUploadSize = offset
                        params[indexPartNumber] = {ETag:data.ETag,PartNumber:partNumber}
                        //current file size will remain the same
                        dispatch(setCurrentUploadSize(file.id,currentUploadSize,params))
                      }else{
                        //If new part number, add another element in params array
                        params.push({ETag:data.ETag,PartNumber:partNumber})
                        //Update upload progress and params
                        const percentageProgress = Math.floor(
                          ((currentUploadSize) / fileSize) * 100
                        )
                        dispatch(setCurrentUploadSize(file.id,currentUploadSize,params))
                        dispatch(setUploadProgress(file.id, percentageProgress));
                      }
                      
                      //Check if this is the last part of concurrency or whole file uploaded
                      
                      
                      if(uploadTasks.length===concurrency || currentUploadSize===fileSize){
                        resolve(uploadTasks)
                        currentTotalTime = (endTime-startTime)/1000
                        if(currentUploadSize===fileSize){
                          dispatch(partUploadComplete(file.id,1))
                          //partUploadComplete = 1;
                        }
                      }
                    }
                  })
                
              }
            })

            
          
          })
          

          
          partResults = await partUploadPromise //wait for current uploads to finish before triggering new batch


          partsArrayLengthSentinel=partsArray.length

          
          
          //Remove successful upload parts in the parts array ( 1 to chunk count ) to be uploaded
          partsArray = partsArray.filter(n => !successfulUploadTasks.includes(n))
          //Determine the optimal number of concurrency based on current total time and timeout
          concurrency = Math.floor((((CHUNK_SIZE*currentUploadArray.length)/currentTotalTime)*120)/CHUNK_SIZE)
          
          uploadTasks = []
          successfulUploadTasks = []
        }


        state = getState()


        if(state.file.fileProgress[file.id].partUploadComplete && state.file.fileProgress[file.id].status!=1 && state.file.fileProgress[file.id].status!=4 && state.file.fileProgress[file.id].status!=5){

          state = getState()
          
          const sortedParamsArray = state.file.fileProgress[file.id].params.sort((a, b) => (a.PartNumber > b.PartNumber) ? 1 : -1)
          

          const completeUploadParams = {
            Bucket: bucket,
            Key: `${uploadPath}${fileName}`,
            MultipartUpload: {
              Parts: sortedParamsArray
            },
            UploadId: uploadID
          }
        
          s3.completeMultipartUpload(completeUploadParams, (err,data) =>{
            if(err){
              console.log(err)
              dispatch(failureUploadFile(file.id));
            }else{
              
              uploadCompleteWrapper(file.id,dispatch,getState)
            }
          })
        
        }
      } catch (error) {
        console.log(error)
        
      }
    });
    
  
  
  }
};



export const setUploadFile = (data,path) => {
  
  return {
    type: SET_UPLOAD_FILE,
    payload: {
      data,
      path,
    }
  };
};

export const openModalUploadFile = (value) => {
  return {
    type: OPEN_MODAL_UPLOAD_FILE,
    payload: value,
  };
};

export const setFileName = (value) => {
  return {
    type: SET_FILE_NAME,
    payload: value,
  };
};

export const addUser = (value) => {
  return {
    type: ADD_USER,
    payload: value,
  };
};

export const setPermissionDefaultEmail = (value) => {

  console.log(value)

  return {
    type: SET_PERMISSION_DEFAULT_EMAIL,
    payload: value,
  };
};

export const setRead = (value) => {
  return {
    type: SET_READ,
    payload: value,
  };
};

export const setWrite = (value) => {
  return {
    type: SET_WRITE,
    payload: value,
  };
};

export const setDelete = (value) => {
  return {
    type: SET_DELETE,
    payload: value,
  };
};

export const setAdmin = (value) => {
  return {
    type: SET_ADMIN,
    payload: value,
  };
};

export const openFormSetPermission = (value) => {
  return {
    type: OPEN_FORM_SET_PERMISSION,
    payload: value,
  };
};

export const openModalSetPermission = (value) => {
  return {
    type: OPEN_MODAL_SET_PERMISSION,
    payload: value,
  };
};

export const openModalCreateFolder = (value) => {
  return {
    type: OPEN_MODAL_CREATE_FOLDER,
    payload: value,
  };
};

export const getPermissionRequest = () => {
  return {
    type: GET_PERMISSION_REQUEST,
  };
};

export const getPermissionSuccess = (success) => {
  return {
    type: GET_PERMISSION_SUCCESS,
    payload: success,
  };
};

export const getPermissionFailure = (error) => {
  return {
    type: GET_PERMISSION_FAILURE,
    payload: error,
  };
};

export const setPermissionRequest = () => {
  return {
    type: SET_PERMISSION_REQUEST,
  };
};

export const setPermissionSuccess = (success) => {
  return {
    type: SET_PERMISSION_SUCCESS,
    payload: success,
  };
};

export const setPermissionFailure = (error) => {
  return {
    type: SET_PERMISSION_FAILURE,
    payload: error,
  };
};

export const refreshPermissionError = () => {
  return {
    type: REFRESH_PERMISSION_ERROR
  };
};

export const createFolderRequest = () => {
  return {
    type: CREATE_FOLDER_REQUEST,
  };
};

export const createFolderSuccess = (success) => {
  return {
    type: CREATE_FOLDER_SUCCESS,
    payload: success,
  };
};

export const createFolderFailure = (error) => {
  return {
    type: CREATE_FOLDER_FAILURE,
    payload: error,
  };
};

export const deleteFileRequest = () => {
  return {
    type: DELETE_FILE_REQUEST,
  };
};

const deleteFileSuccess = (success) => {
  return {
    type: DELETE_FILE_SUCCESS,
    payload: success,
  };
};

const deleteFileFailure = (error) => {
  return {
    type: DELETE_FILE_FAILURE,
    payload: error,
  };
};

export const uploadFileRequest = () => {
  return {
    type: UPLOAD_FILE_REQUEST,
  };
};

const uploadFileSuccess = (success) => {
  return {
    type: UPLOAD_FILE_SUCCESS,
    payload: success,
  };
};

const uploadFileFailure = (error) => {
  return {
    type: UPLOAD_FILE_FAILURE,
    payload: error,
  };
};

export const storeFileUpload = (file) => {
  return {
    type: STORE_FILE_UPLOAD,
    payload: file,
  };
};

export const fetchFileRequest = () => {
  return {
    type: FETCH_FILE_REQUEST,
  };
};

const fetchFileSuccess = (success, path) => {
  return {
    type: FETCH_FILE_SUCCESS,
    payload: success,
    path: path,
  };
};

const fetchFileFailure = (error) => {
  return {
    type: FETCH_FILE_FAILURE,
    payload: error,
  };
};

export const checkFileConflictBeforeUpload = () => {

  const accessToken = localStorage.getItem('accessToken');
  
  return (dispatch, getState) => {
    const state = getState();
    const path = state.file.path;
    let files = state.file.file;
    
    

    let uploadPath = path

    

    if(!(path.startsWith('Shared by other Users/') || path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`) || path.length===0)){
      uploadPath = `Shared by other Users/${localStorage.getItem('email')}/${path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`
    }


    let incomingFiles = Object.keys(files).map(key => `${uploadPath}${files[key].name}`);

    ////////////
    if(files[0].webkitRelativePath!='' && files[0].webkitRelativePath!=null){
      incomingFiles = Object.keys(files).map(key => `${uploadPath}${files[key].webkitRelativePath}`);
    }
    ////////////
    

    axios.get(`${process.env.REACT_APP_API_BASE_URL}/storage/getallfiles`, {
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: uploadPath
      }
    }).then(response=>{
      let existingFiles = response.data.map(file => file.name)
      
      let intersection = incomingFiles.filter((x)=>{
        if(existingFiles.indexOf(x)!=-1){
          return true;
        }else{
          return false;
        }
      })
      
      intersection = intersection.map(file=>file.replace(uploadPath,''))
      
      dispatch(setConflictFiles(intersection))
      if(intersection.length===0){
        let filesArray = Object.values(files)
        if(filesArray[0].webkitRelativePath!='' && filesArray[0].webkitRelativePath!=null){
          let listOfFilesWithFolder = getListOfFilesWithFolders(filesArray,state)
          let listOfFoldersToBeCreated = getListOfFoldersToBeCreated(listOfFilesWithFolder)
          console.log('*********** LIST OF FOLDERS TO BE CREATED')
          console.log(listOfFoldersToBeCreated)
          createFoldersDuringFolderUpload(listOfFoldersToBeCreated, state, (result)=>{ 
            console.log('#################### DONE CREATING ALL FOLDER ##########################')
            console.log(result)
            dispatch(setUploadFile(files,uploadPath))
          })
        }else{
          dispatch(setUploadFile(files,uploadPath))
        }
        
      }
      dispatch(retryToken(false))
    }).catch(error=>{
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(checkFileConflictBeforeUpload())
          })
          dispatch(retryToken(true))
        }else{
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
    })
  }
}


export const fetchFiles = (values) => {
  const path = values ? values : '';
  const accessToken = localStorage.getItem('accessToken');

  //console.log(path)
  

  return (dispatch, getState) => {
    dispatch(fetchFileRequest());
    axios
      .get(`${process.env.REACT_APP_API_BASE_URL}/storage/files`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
          path: path,
        },
      })
      .then((response) => {
        
        let files = response.data.objects;
        const email = localStorage.getItem('email')

        //Replace own folder name to "My Personal Folder"
        files = files.map((file) => 
          file.name.replace(`Shared by other Users/${email}/`,process.env.REACT_APP_PERSONAL_FOLDER)
        )

        

        //Remove own files in "Shared by other users" path
        if(path==='Shared by other Users/'){
          files = files.filter(item => (item.includes(`Shared by other Users/`)));
          
        }

        

        //Remove previous path
        files = files.map((file) => file.replace(path, ''));

        

        files = files.map((file) => ({
          name: file.split('/')[0],
          type: file.split('/').length - 1 > 0 ? 'folder' : 'file',
        }));
        const seen = new Set();
        files = files.filter((el) => {
          const duplicate = seen.has(el.name);
          seen.add(el.name);
          return !duplicate;
        });
        files = files.filter((el) => {
          return el.name != '';
        });
        dispatch(fetchFileSuccess(files, path));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response.status)
        const errorMessage = error.message;
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(fetchFiles(values))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(fetchFileFailure(errorMessage)); 
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};







export const downloadFile = (values) => {

  const accessToken = localStorage.getItem('accessToken');
  let path = values;
  let fileName = '';
  const getFileNameSize = path.split('/').length
  let fileSize = 0;

  if(path[path.length-1]==='/'){
    fileName = `${path.split('/')[getFileNameSize-2]}.zip`
  }else{
    fileName = path.split('/')[getFileNameSize-1]
  }

  

  
  return (dispatch, getState) => {

    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/files`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: path,
      },
    }).then(async (listfiles) => {
      dispatch(retryToken(false))
      

      let arrayFileNames = listfiles.data.objects.map(file => file.name);
      arrayFileNames = arrayFileNames.filter(name => name[name.length-1]!='/')

      


      if(!(path.startsWith('Shared by other Users/') || path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
        
        path = `Shared by other Users/${localStorage.getItem('email')}/${path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`
      }

      if(path[path.length-1]==='/'){

        arrayFileNames.map((file)=>{
          const params = {Bucket: bucket, Key: file, Expires: 60}
          
          s3.getSignedUrl('getObject', params, (err, url) => {
            if(err){
              console.log(err)
            }else{
              
              window.open(url, file)
              
            }
          })
        })
      }else{
        
        const params = {Bucket: bucket, Key: path, Expires: 60};
        
        s3.getSignedUrl('getObject', params, function (err, url) {
          if (err) {
            console.log(err)
          }else{
            window.open(url, '_blank')
            
          }
        })
      }
    }).catch((error) => {
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(downloadFile(values))
          })
          dispatch(retryToken(true))
        }else{
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
    });
    
  
    
    
  };
};


export const deleteFile = () => {
  const accessToken = localStorage.getItem('accessToken');
  return (dispatch, getState) => {
    const state = getState();
    const currentPath = state.file.path;
    const fileName = state.file.fileName
    
    dispatch(deleteFileRequest());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/delete`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: `${currentPath}${fileName}`,
      },
    })
      .then((response) => {
        dispatch(deleteFileSuccess(response));
        dispatch(fetchFiles(currentPath));
        dispatch(openDeleteFileDialog(false))
        dispatch(retryToken(false))
        
      })
      .catch((error) => {
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(deleteFile())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(deleteFileFailure(error.response.data.message));
          dispatch(openAlertFileDeleteFailure(true))
          dispatch(openDeleteFileDialog(false))
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const createFolder = (values) => {
  const accessToken = localStorage.getItem('accessToken');
  return (dispatch, getState) => {
    const state = getState();
    const path = state.file.path;
    const folder = values.folderName;
    dispatch(createFolderRequest());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/folder`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: path,
        'Content-Type': 'application/json',
      },
      data: {
        folder: `${folder}/`,
      },
    })
      .then((response) => {
        
        dispatch(createFolderSuccess(response));
        dispatch(openModalCreateFolder(false));
        dispatch(fetchFiles(path));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(createFolder(values))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(createFolderFailure(error.response.data.message));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const setPermission = (email) => {
  const accessToken = localStorage.getItem('accessToken');
  return (dispatch, getState) => {
    const state = getState();
    
    const userEmail = email ? email : state.file.permissionDefaultEmail;
    const path = `${state.file.path}${state.file.fileName}`;
    const readPermission = state.file.read;
    const writePermission = state.file.write;
    const deletePermission = state.file.delete;
    const adminPermission = state.file.admin;
    
    dispatch(setPermissionRequest());
    axios({
      method: 'post',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/permission`,
      headers: {
        path: path,
        Authorization: `Bearer ${accessToken}`,
      },
      data: {
        email: userEmail,
        read: true,
        write: writePermission,
        delete: deletePermission,
        admin: adminPermission,
      },
    })
      .then((response) => {
        dispatch(openModalSetPermission(false));
        dispatch(openFormSetPermission(false));
        dispatch(setPermissionDefaultEmail());
        dispatch(setPermissionSuccess(response));
        dispatch(fetchFiles(state.file.path));
        dispatch(retryToken(false))
        
      })
      .catch((error) => {
        console.log(error);
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(setPermission(email))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(setPermissionFailure(error.response.data.message));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const getPermission = (file) => {
  const accessToken = localStorage.getItem('accessToken');
  return (dispatch, getState) => {
    dispatch(getPermissionRequest());
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/permission`,
      headers: {
        path: file,
        Authorization: `Bearer ${accessToken}`,
      },
    })
      .then((response) => {
        dispatch(getPermissionSuccess(response.data));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(getPermission(file))
          })
          dispatch(retryToken(true))
        }else{
          dispatch(getPermissionFailure(error.response.data.message));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
};

export const checkOpenFileUploadModal = () => {

  const accessToken = localStorage.getItem('accessToken');

  return (dispatch, getState) => {
    const state = getState();
    const path = state.file.path;
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/writepermission`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: path,
      }
    })
      .then((response) => {
        dispatch(openModalUploadFile(true));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(checkOpenFileUploadModal())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(openAlertFileUploadModalFailure(true));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
}

export const checkOpenCreateFolderModal = () => {

  const accessToken = localStorage.getItem('accessToken');

  return (dispatch, getState) => {
    const state = getState();
    const path = state.file.path;
    axios({
      method: 'get',
      url: `${process.env.REACT_APP_API_BASE_URL}/storage/writepermission`,
      headers: {
        Authorization: `Bearer ${accessToken}`,
        path: path,
      }
    })
      .then((response) => {
        
        dispatch(openModalCreateFolder(true));
        dispatch(retryToken(false))
      })
      .catch((error) => {
        console.log(error.response.status)
        const state = getState()
        const retryOccurred = state.control.retryToken
        
        if(!retryOccurred && error.response.status===401){
          renewTokenWrapper(dispatch).then(() => {
            dispatch(checkOpenCreateFolderModal())
          })
          dispatch(retryToken(true))
        }else{
          dispatch(openAlertFileUploadModalFailure(true));
          if(error.response.status===401){
            console.log('Need to logout')
            dispatch(renewTokenExpired(true));
          }
        }
      });
  };
}
