import { combineReducers } from 'redux'

import loginReducer from './login/loginReducer'
import fileReducer from './files/fileReducer'
import registerReducer from './register/registerReducer'
import controlReducer from './controls/controlReducer'


const rootReducer = combineReducers({
    login: loginReducer,
    register: registerReducer,
    file: fileReducer,
    control: controlReducer
})

export default rootReducer