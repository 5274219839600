import React from 'react'
import {
    Cancel
} from '@material-ui/icons'
import { 
    red
} from '@material-ui/core/colors';
import {
    CircularProgress
} from '@material-ui/core/'


import Styles from '../css/UploadItem.module.css'


const UploadItemAbortInProgress = props => {
    const { file, progress } = props.file

    
    
    return (
        <div className={Styles.wrapperItemDone}>
            <div className={Styles.leftSideDone}>
                <label>Aborting upload...</label> 
                <label>{file.name}</label> 
            </div>
            <span className={Styles.percentage}>
                <CircularProgress size={20}/>     
            </span>
        </div>
    )
}

export default UploadItemAbortInProgress