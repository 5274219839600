import React from 'react';
import { connect } from 'react-redux'
import { 
    openModalSetPermission, 
    openFormSetPermission,
    setAdmin,
    setDelete,
    setRead,
    setWrite,
    setPermissionDefaultEmail,
    addUser,
    setPermission,
    deletePermission,
    refreshPermissionError,
    fetchUsers,
    showSearchDropdown
 } from '../redux'
import Dialog from '@material-ui/core/Dialog';
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useForm } from 'react-hook-form'
import { 
    Button, 
    Grid, 
    Typography, 
    List, 
    ListItem, 
    ListItemIcon, 
    ListItemText,
    FormGroup,
    FormControlLabel,
    Checkbox,
    Box
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { 
    Person, 
    AddCircle,
    Remove 
} from '@material-ui/icons'

import DropDownUsersContainer from './DropDownList'

import Styles from '../css/file.css'


const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      maxWidth: 752,
    },
    demo: {
      backgroundColor: theme.palette.background.paper
    },
    title: {
      margin: theme.spacing(4, 0, 2),
      marginRight: "5px",
      marginLeft: "5px"
    },
    listnames: {
      maxHeight: "150px",
      overflow: "scroll"
    },
    fileName: {
        maxWidth: "275px",
        overflow: "hidden",
        textOverflow: "ellipsis",
        whiteSpace: "nowrap",
        marginLeft: "20px",
        marginRight: "20px",
        textAlign: "center"
    },
    labelList: {
        maxWidth: "225px",
        marginLeft: "20px",
        marginRight: "20px",
        marginTop: "5px",
        marginBottom: "5px",
        fontSize: "medium"
    },
    label: {
        maxWidth: "225px",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        textAlign: "center",
        fontSize: "small"
    },
    errorLabel: {
        maxWidth: "225px",
        marginLeft: "5px",
        marginRight: "5px",
        marginTop: "5px",
        marginBottom: "5px",
        textAlign: "center",
        fontSize: "small",
        color: "red"
    }
  }));


const SetPermissionModal = ({ showSearchDropdown, control, response, openModalSetPermission, openFormSetPermission, setRead, setWrite, setDelete, setAdmin, setPermissionDefaultEmail, addUser, setPermission, deletePermission, refreshPermissionError, fetchUsers}) => {

  const { handleSubmit, register, errors } = useForm();
  
  const onSubmit = values => {
      
      setPermission(values.userEmail)
  };

  const classes = useStyles();

  const handleChangeRead = (event) =>{
    
    setRead(event.target.checked)
  }

  const handleChangeWrite = (event) =>{
    
    setWrite(event.target.checked)
  }

  const handleChangeAdmin = (event) =>{
    
    setAdmin(event.target.checked)
  }

  const handleChangeDelete = (event) =>{
    
    setDelete(event.target.checked)
  }

  const updateDropDownList = (event) => {
      //console.log("Textfield updated")
      setPermissionDefaultEmail(event.target.value)
      fetchUsers(event.target.value)
      showSearchDropdown(true)
  }

  //console.log("Opened set permission modal");

  return (
    <div>
      <Dialog open={response.modalSetPermissionIsOpen} onClose={()=>{
          openModalSetPermission(false)
          openFormSetPermission(false)
          refreshPermissionError()
          setPermissionDefaultEmail('')
        }} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Share file to other users</DialogTitle>
        <br />
        {(response.getPermissionError==='')
        ?<div className={Styles.wrapper}>
            <div>
                <div className={classes.fileName}><b>{response.fileName}</b></div>       
            </div>
            <br />
            <div>
            <div className={classes.labelList}>Currently shared with:</div>
            </div>
            <div>
                <Grid item xs={12}>
                    <div className={classes.listnames}>
                            <List dense={true}>
                                {response.getPermissionStatus.map(file=>
                                    <ListItem fullWidth button onClick={()=>{
                                        openFormSetPermission(true)
                                        setPermissionDefaultEmail(file.user.email)
                                        setRead(file.read)
                                        setWrite(file.write)
                                        setDelete(file.delete)
                                        setAdmin(file.admin)
                                        addUser(false)
                                    }}>
                                        <ListItemIcon>
                                            <Person />
                                        </ListItemIcon>
                                        <ListItemText
                                            primary={file.user.email}
                                        />
                                    </ListItem>
                                )}
                            </List>
                        </div>
                    <div>
                        <List dense={true}>
                            <ListItem fullWidth button onClick={()=>{
                                            openFormSetPermission(true)
                                            setPermissionDefaultEmail('')
                                            setRead(false)
                                            setWrite(false)
                                            setDelete(false)
                                            setAdmin(false)
                                            addUser(true)
                                        }}>
                                    <ListItemIcon>
                                        <AddCircle />
                                    </ListItemIcon>
                                    <ListItemText primary="Add user"/>
                                </ListItem>
                        </List>
                    </div>
                </Grid>
            </div>
        </div>
        :<div className={classes.demo}>Cannot view information.</div>
        }
        {response.formSetPermissionIsOpen &&
        <form onSubmit={handleSubmit(onSubmit)}>

            <DialogContent>
            {!(response.addUser) && <DialogContentText>
                {response.permissionDefaultEmail}
            </DialogContentText>}
            {response.addUser && <TextField autoFocus margin="dense" value={response.permissionDefaultEmail} defaultValue={response.permissionDefaultEmail} fullWidth name="userEmail" type="text" placeholder="User email" inputRef={register} onChange={updateDropDownList}/>}
            {response.addUser && response.permissionDefaultEmail!='' && control.showSearchDropdown && <Box position="relative"><DropDownUsersContainer /></Box>}
            {
                (response.setPermissionError!='') && <div className={classes.errorLabel}>
                    Error in setting permission. User does not exist.
                </div>
            }
            <div className={classes.label}>
            If assigning a "read only" permission, enter an email address then share without ticking a box
            </div>
            <FormGroup>
                <FormControlLabel
                    control={<Checkbox checked={response.write} onChange={handleChangeWrite} name="write" />}
                    label="write"
                />
                <FormControlLabel
                    control={<Checkbox checked={response.delete} onChange={handleChangeDelete} name="delete" />}
                    label="delete"
                />
                <FormControlLabel
                    control={<Checkbox checked={response.admin} onChange={handleChangeAdmin} name="admin" />}
                    label="reshare"
                />
            </FormGroup>
            
            </DialogContent>
            <DialogActions>
                {!(response.addUser) && <Button 
                onClick={()=>{
                    deletePermission()
                }}>
                    <span style={{color: 'red'}}>Remove</span>
                </Button>}
                <Button type="submit" color="primary">
                    Share
                </Button>   
            </DialogActions>
        </form>
        }
        
        {!(response.formSetPermissionIsOpen) && <Button onClick={()=>{
            openModalSetPermission(false)
            openFormSetPermission(false)
            refreshPermissionError()
            setPermissionDefaultEmail('')
        }} color="primary">
            Cancel
        </Button>}|
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.file,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        setPermissionDefaultEmail: (values) => dispatch(setPermissionDefaultEmail(values)),
        openModalSetPermission: (values) => dispatch(openModalSetPermission(values)),
        openFormSetPermission: (values) => dispatch(openFormSetPermission(values)),
        setRead: (values) => dispatch(setRead(values)),
        setWrite: (values) => dispatch(setWrite(values)),
        setDelete: (values) => dispatch(setDelete(values)),
        setAdmin: (values) => dispatch(setAdmin(values)),
        addUser: (values) => dispatch(addUser(values)),
        setPermission: (values) => dispatch(setPermission(values)),
        deletePermission: (values) => dispatch(deletePermission(values)),
        refreshPermissionError: () => dispatch(refreshPermissionError()),
        fetchUsers: (values) => dispatch(fetchUsers(values)),
        showSearchDropdown: (values) => dispatch(showSearchDropdown(values)),
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SetPermissionModal)