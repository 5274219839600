import {
    FETCH_REGISTER_FAILURE,
    FETCH_REGISTER_REQUEST,
    FETCH_REGISTER_SUCCESS,
    FETCH_USERS_FAILURE,
    FETCH_USERS_REQUEST,
    FETCH_USERS_SUCCESS,
    OPEN_MODAL_REGISTER,
    SET_ADMIN_REGISTER,
    SET_USER_INFORMATION_DISPLAY,
    CHANGE_PASSWORD_FAILURE,
    CHANGE_PASSWORD_REQUEST,
    CHANGE_PASSWORD_SUCCESS,
    OPEN_ALERT_CHANGE_PASSWORD_FAILURE,
    OPEN_ALERT_CHANGE_PASSWORD_SUCCESS,
    OPEN_ALERT_ADD_USER_FAILURE,
    OPEN_ALERT_ADD_USER_SUCCESS,
    DELETE_USERS_FAILURE,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    OPEN_ALERT_DELETE_USER_SUCCESS,
    GENERATE_PASSWORD_FAILURE,
    GENERATE_PASSWORD_REQUEST,
    GENERATE_PASSWORD_SUCCESS,
    UPDATE_USER_FAILURE,
    UPDATE_USER_REQUEST,
    UPDATE_USER_SUCCESS,
    REGISTER_RESET
} from './registerTypes'

const initialState = {
    loading: false,
    response: '',
    error: '',
    usersInProgress: false,
    usersResponse: [],
    usersError: '',
    modalRegisterIsOpen: false,
    isAdmin: false,
    userInformation: {},
    changePasswordInProgress: false,
    changePasswordResponse: '',
    changePasswordError: '',
    openAlertChangePasswordSuccess: false,
    openAlertChangePasswordFailure: false,
    openAlertAddUserSuccess: false,
    openAlertAddUserFailure: false,
    deleteUsersInProgress: false,
    deleteUsersResponse: '',
    deleteUsersError: '',
    openAlertDeleteUserSuccess: false,
    generatePasswordInProgress: false,
    generatePasswordResponse: '',
    generatePasswordError: '',
    userUpdateInProgress: false,
    userUpdateResponse: '',
    userUpdateError: ''
}

const reducer = (state = initialState, action) => {
    switch(action.type){
        case REGISTER_RESET:
            return {
                ...state,
                ...initialState
            }
        case UPDATE_USER_FAILURE:
            return {
                ...state,
                userUpdateError: action.payload,
                userUpdateResponse: '',
                userUpdateInProgress: false
            }
        case UPDATE_USER_REQUEST:
            return {
                ...state,
                userUpdateInProgress: true,
                userUpdateResponse: '',
                userUpdateError: ''
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                userUpdateInProgress: false,
                userUpdateResponse: action.payload,
                userUpdateError: ''
            }
        case GENERATE_PASSWORD_FAILURE:
            return {
                ...state,
                generatePasswordError: action.payload,
                generatePasswordResponse: '',
                generatePasswordInProgress: false
            }
        case GENERATE_PASSWORD_REQUEST:
            return {
                ...state,
                generatePasswordInProgress: true,
                generatePasswordError: '',
                generatePasswordResponse: ''
            }
        case GENERATE_PASSWORD_SUCCESS:
            return {
                ...state,
                generatePasswordError: '',
                generatePasswordInProgress: false,
                generatePasswordResponse: action.payload
            }
        case OPEN_ALERT_DELETE_USER_SUCCESS:
            return {
                ...state,
                openAlertDeleteUserSuccess: action.payload
            }
        case DELETE_USERS_FAILURE:
            return {
                ...state,
                deleteUsersInProgress: false,
                deleteUsersResponse: '',
                deleteUsersError: action.payload
            }
        case DELETE_USERS_REQUEST:
            return {
                ...state,
                deleteUsersInProgress: true,
                deleteUsersResponse: '',
                deleteUsersError: ''
            }
        case DELETE_USERS_SUCCESS:
            return {
                ...state,
                deleteUsersInProgress: false,
                deleteUsersError: '',
                deleteUsersResponse: action.payload
            }
        case OPEN_ALERT_ADD_USER_FAILURE:
            return {
                ...state,
                openAlertAddUserFailure: action.payload
            }
        case OPEN_ALERT_ADD_USER_SUCCESS:
            return {
                ...state,
                openAlertAddUserSuccess: action.payload
            }
        case OPEN_ALERT_CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                openAlertChangePasswordFailure: action.payload
            }
        case OPEN_ALERT_CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                openAlertChangePasswordSuccess: action.payload
            }
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                changePasswordInProgress: false,
                changePasswordResponse: '',
                changePasswordError: ''
            }
        case CHANGE_PASSWORD_REQUEST:
            return {
                ...state,
                changePasswordInProgress: true,
                changePasswordResponse: '',
                changePasswordError: ''
            }
        case CHANGE_PASSWORD_FAILURE:
            return {
                ...state,
                changePasswordInProgress: false,
                changePasswordResponse: '',
                changePasswordError: action.payload
            }
        case SET_USER_INFORMATION_DISPLAY:
            return {
                ...state,
                userInformation: action.payload
            }
        case SET_ADMIN_REGISTER:
            return {
                ...state,
                isAdmin: action.payload
            }
        case OPEN_MODAL_REGISTER:
            return {
                ...state,
                modalRegisterIsOpen: action.payload
            }
        case FETCH_USERS_FAILURE:
            return {
                ...state,
                usersInProgress: false,
                usersResponse: [],
                usersError: action.payload
            }
        case FETCH_USERS_SUCCESS:
            return {
                ...state,
                usersInProgress: false,
                usersResponse: action.payload,
                usersError: ''
            }
        case FETCH_USERS_REQUEST:
            return {
                ...state,
                usersInProgress: true,
                usersResponse: [],
                usersError: ''
            }
        case FETCH_REGISTER_REQUEST:
            return {
                ...state,
                loading: true
            }
        case FETCH_REGISTER_SUCCESS:
            return {
                ...state,
                loading: false,
                response: action.payload,
                error: ''
            }
        case FETCH_REGISTER_FAILURE:
            return {
                ...state,
                loading: false,
                response: '',
                error: action.payload
            }
        default: return state
    }
}

export default reducer