import React from 'react';
import { connect } from 'react-redux'
import { makeStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { 
    openFileSizeUnitDropDown,
    setUnit 
} from '../redux';

const useStyles = makeStyles((theme) => ({
  button: {
    display: 'block',
    //marginTop: theme.spacing(2),
  },
  formControl: {
    //margin: theme.spacing(1),
    minWidth: 50,
  },
}));

const DropwdownFileSizeUnit = ({ control , openFileSizeUnitDropDown, setUnit}) =>{
  const classes = useStyles();

  const handleChange = (event) => {
    setUnit(event.target.value);
  };

  const handleClose = () => {
    openFileSizeUnitDropDown(false);
  };

  const handleOpen = () => {
    openFileSizeUnitDropDown(true);
  };

  return (
    <div>
      <FormControl className={classes.formControl}>
        <InputLabel id="file-size-unit-open-select-label">unit</InputLabel>
        <Select
          labelId="file-size-unit-open-select-label"
          id="file-size-unit-open-select"
          open={control.openFileSizeUnitDropDown}
          onClose={handleClose}
          onOpen={handleOpen}
          value={control.setUnit}
          onChange={handleChange}
        >
          <MenuItem value={1073741824}>GB</MenuItem>
          <MenuItem value={1099511627776}>TB</MenuItem>
        </Select>
      </FormControl>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        control: state.control
    }
  }
  
  const mapDispatchToProps = dispatch => {
    return {
        openFileSizeUnitDropDown: (values) => dispatch(openFileSizeUnitDropDown(values)),
        setUnit: (values) => dispatch(setUnit(values))
    }
  }
  
  export default connect(
    mapStateToProps,
    mapDispatchToProps
  )(DropwdownFileSizeUnit)