import { createStore, applyMiddleware } from 'redux'
import thunk from 'redux-thunk'
import rootReducer from './rootReducer'
import { composeWithDevTools } from 'redux-devtools-extension'

/*const store = createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(thunk))
)*/

const store = createStore(
    rootReducer,
    process.env.REACT_APP_ENV === 'production'? applyMiddleware(thunk):composeWithDevTools(applyMiddleware(thunk))
)

export default store