import React from 'react'
import { connect } from 'react-redux'
import { 
    openFileInformationModal
 } from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button
} from '@material-ui/core'




const FileInformationModal = ({ control, response, openFileInformationModal }) => {

  
  let uploadPath = response.path
  let actualFileSize = 0
  let actualFileSizeString = ''

  if(response.getFileInformationData.fileSize>=1099511627776){
      actualFileSize = response.getFileInformationData.fileSize/1099511627776
      actualFileSizeString = `${actualFileSize.toFixed(2)} TB`
  }else if(response.getFileInformationData.fileSize>=1073741824){
      actualFileSize = response.getFileInformationData.fileSize/1073741824
      actualFileSizeString = `${actualFileSize.toFixed(2)} GB`
  }else if(response.getFileInformationData.fileSize>=1048576){
      actualFileSize = response.getFileInformationData.fileSize/1048576
      actualFileSizeString = `${actualFileSize.toFixed(2)} MB`
  }else{
      actualFileSize = response.getFileInformationData.fileSize/1024
      actualFileSizeString = `${actualFileSize.toFixed(2)} KB`
  }

  if(!(response.path.startsWith('Shared by other Users/') || response.path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
    
    uploadPath=`Shared by other Users/${localStorage.getItem('email')}/${response.path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`

  }

  return (
    <div>
      <Dialog open={control.openFileInformationModal} onClose={()=>openFileInformationModal(false)} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">File Information</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    File Type: {response.getFileInformationData.fileType}
                </DialogContentText>
                <DialogContentText>
                    File Size: {actualFileSizeString}
                </DialogContentText>
            </DialogContent>
            <DialogActions>
                <Button onClick={()=>openFileInformationModal(false)} color="secondary">
                    Close
                </Button>
            </DialogActions>
        
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.file,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openFileInformationModal: (values) => dispatch(openFileInformationModal(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(FileInformationModal)