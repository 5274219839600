import React from 'react'
import { connect } from 'react-redux'
import { openModalCreateFolder, createFolder, checkFolderExist, setFolderExistIndicator } from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import TextField from '@material-ui/core/TextField'
import { 
    Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

const CreateFolderForm = ({response, setFolderExistIndicator, checkFolderExist, openModalCreateFolder, createFolder}) => {
    
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = values => {
      createFolder(values);
      setFolderExistIndicator(0);
  }

  const checkIfFolderExists = (event) => {
      checkFolderExist(event.target.value,response.response)
  }

  return (
    <Dialog open={response.modalCreateFolderIsOpen} onClose={()=>openModalCreateFolder(false)} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Create folder</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <TextField autoFocus margin="dense" name="folderName" type="text" placeholder="Folder name" inputRef={register} onChange={checkIfFolderExists}/>
            <DialogContentText>
                {response.createFolderError.toString()}
            </DialogContentText>
            {
                (response.folderExists===1)
                ?<DialogContentText>
                    <span style={{color: 'red'}}>Folder with the same name exists.</span>
                </DialogContentText>
                :null
            }
        </DialogContent>
        <DialogActions>
            <Button 
            onClick={()=>{
                openModalCreateFolder(false)
                setFolderExistIndicator(0)
                }
            } color="primary">
                Cancel
            </Button>
            <Button disabled={((response.folderExists!=1))?false:true} color="primary" type="submit">
                Create
            </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

const mapStateToProps = state => {
    return {
        response: state.file
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openModalCreateFolder: (values) => dispatch(openModalCreateFolder(values)),
        createFolder: (values) => dispatch(createFolder(values)),
        checkFolderExist: (newfolder,currentfolders) => dispatch(checkFolderExist(newfolder,currentfolders)),
        setFolderExistIndicator: (values) => dispatch(setFolderExistIndicator(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(CreateFolderForm)