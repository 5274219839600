import React from 'react'
import { connect } from 'react-redux'
import {
    openConflictFileModal,
    setApplyToAll,
    conflictOverwrite,
    conflictKeepBothFiles
 } from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button,
    FormGroup,
    FormControlLabel,
    Checkbox
} from '@material-ui/core'




const ConflictFileModal = ({ response, setApplyToAll, conflictOverwrite, conflictKeepBothFiles }) => {

  const handleApplyToAll = (event) =>{
    
    setApplyToAll(event.target.checked)
  }
  
  
  let uploadPath = response.path

  if(!(response.path.startsWith('Shared by other Users/') || response.path.startsWith(`${process.env.REACT_APP_COMMON_FOLDER}/`))){
    
    uploadPath=`Shared by other Users/${localStorage.getItem('email')}/${response.path.replace(process.env.REACT_APP_PERSONAL_FOLDER,'')}`

  }

  return (
    <div>
      <Dialog open={response.conflictFiles.length>0} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">File already exists</DialogTitle>
            <DialogContent>
                <DialogContentText>
                    A file with the name {response.conflictFiles[0]} already exists in this location
                </DialogContentText>
                <FormGroup>
                    <FormControlLabel
                        control={<Checkbox checked={response.applyToAll} onChange={handleApplyToAll} name="applyToAll" />}
                        label="Apply this option to all conflicting items"
                    />
                </FormGroup>
            </DialogContent>
            <DialogActions>
                <Button color="secondary" onClick={()=>conflictKeepBothFiles()}>
                    Keep Both Files
                </Button>
                <Button color="primary" onClick={()=>conflictOverwrite()}>
                    Overwrite
                </Button>
            </DialogActions>
        
      </Dialog>
    </div>
  );
}

const mapStateToProps = state => {
    return {
        response: state.file,
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openConflictFileModal: (values) => dispatch(openConflictFileModal(values)),
        setApplyToAll: (values) => dispatch(setApplyToAll(values)),
        conflictOverwrite: () => dispatch(conflictOverwrite()),
        conflictKeepBothFiles: () => dispatch(conflictKeepBothFiles())
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConflictFileModal)