import React from 'react'
import {
    CheckCircle
} from '@material-ui/icons'
import { 
    green 
} from '@material-ui/core/colors';


import Styles from '../css/UploadItem.module.css'


const UploadItemDone = props => {
    const { file, progress } = props.file

    
    
    return (
        <div className={Styles.wrapperItemDone}>
            <div className={Styles.leftSideDone}>
                <label>Successful upload...</label> 
                <label>{file.name}</label>  
            </div>
            <span className={Styles.percentage}>
                <CheckCircle fontSize="medium" style={{ color: green[500] }}/>     
            </span>
        </div>
    )
}

export default UploadItemDone