import { registerReset } from '../register/registerActions'
import { fileReset } from '../files/fileActions'
import { loginReset } from '../login/loginActions'
import {
    OPEN_HOME_DRAWER,
    SET_NAVIGATION_TITLE,
    TOGGLE_PROGRESS_BAR,
    TOGGLE_ACCOUNT_OPTIONS,
    OPEN_USER_INFORMATION,
    SET_PROGRESS_BAR_VISIBILITY,
    OPEN_CHANGE_PASSWORD_FORM,
    OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE,
    OPEN_DELETE_FILE_DIALOG,
    OPEN_DELETE_USER_DIALOG,
    OPEN_GENERATE_PASSWORD_DIALOG,
    OPEN_CONFLICT_FILE_MODAL,
    OPEN_ALERT_FILE_DELETE_FAILURE,
    OPEN_FILE_INFORMATION_MODAL,
    OPEN_UPDATE_USER_INFORMATION_FORM,
    OPEN_FILE_SIZE_UNIT_DROPDOWN,
    SET_UNIT,
    RETRY_TOKEN,
    RENEW_TOKEN_EXPIRED,
    CONTROL_RESET,
    SHOW_SEARCH_DROPDOWN,
    ALLOW_FILE_UPLOAD,
    ALLOW_FOLDER_UPLOAD,
} from './controlTypes'

export const allowFolderUpload = value => {
    return {
        type: ALLOW_FOLDER_UPLOAD,
        payload: value
    }
}


export const allowFileUpload = value => {
    return {
        type: ALLOW_FILE_UPLOAD,
        payload: value
    }
}


export const showSearchDropdown = value => {

    //console.log('show search dropdown')
    //console.log(value)

    return {
        type: SHOW_SEARCH_DROPDOWN,
        payload: value
    }
}



export const controlReset = () => {
    return {
        type: CONTROL_RESET
    }
}

export const logOut = () => {
  
    return (dispatch) => {
        dispatch(controlReset())
        dispatch(fileReset())
        dispatch(loginReset())
        dispatch(registerReset())
    }
}

export const renewTokenExpired = value => {
    return {
        type: RENEW_TOKEN_EXPIRED,
        payload: value
    }
}

export const retryToken = value => {
    return {
        type: RETRY_TOKEN,
        payload: value
    }
}

export const setUnit = value => {
    return {
        type: SET_UNIT,
        payload: value
    }
}

export const openFileSizeUnitDropDown = value => {
    return {
        type: OPEN_FILE_SIZE_UNIT_DROPDOWN,
        payload: value
    }
}

export const openUpdateUserInformationForm = value => {
    return {
        type: OPEN_UPDATE_USER_INFORMATION_FORM,
        payload: value
    }
}

export const openFileInformationModal = value => {
    return {
        type: OPEN_FILE_INFORMATION_MODAL,
        payload: value
    }
}

export const openAlertFileDeleteFailure = value => {
    return {
        type: OPEN_ALERT_FILE_DELETE_FAILURE,
        payload: value
    }
}

export const openConflictFileModal = value => {
    return {
        type: OPEN_CONFLICT_FILE_MODAL,
        payload: value
    }
}

export const openGeneratePasswordDialog = value => {
    return {
        type: OPEN_GENERATE_PASSWORD_DIALOG,
        payload: value
    }
}

export const openDeleteUserDialog = value => {
    return {
        type: OPEN_DELETE_USER_DIALOG,
        payload: value
    }
}

export const openDeleteFileDialog = value => {
    return {
        type: OPEN_DELETE_FILE_DIALOG,
        payload: value
    }
}

export const openAlertFileUploadModalFailure = value => {
    return {
        type: OPEN_ALERT_FILE_UPLOAD_MODAL_FAILURE,
        payload: value
    }
}

export const openChangePasswordForm = value => {
    return {
        type: OPEN_CHANGE_PASSWORD_FORM,
        payload: value
    }
}

export const setProgressBarVisibility = value => {
    return {
        type: SET_PROGRESS_BAR_VISIBILITY,
        payload: value
    }
}

export const openUserInformation = value => {
    
    return {
        type: OPEN_USER_INFORMATION,
        payload: value
    }
}

export const openAccountOptions = (values) => {
    return {
        type: TOGGLE_ACCOUNT_OPTIONS,
        payload: values
    }
}

export const openHomeDrawer = (values) => {
    return {
        type: OPEN_HOME_DRAWER,
        payload: values
    }
}

export const setNavigationTitle = (values) => {
    return {
        type: SET_NAVIGATION_TITLE,
        payload: values
    }
}

export const toggleProgressBar = () => {
    return {
        type: TOGGLE_PROGRESS_BAR
    }
}