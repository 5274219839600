import React from 'react'
import { connect } from 'react-redux'
import { 
    openGeneratePasswordDialog,
    generatePassword
} from '../redux'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import { 
    Button
} from '@material-ui/core'
import { useForm } from 'react-hook-form'

const GeneratePasswordDialogForm = ({control, openGeneratePasswordDialog, generatePassword}) => {
    
  const { handleSubmit, register, errors } = useForm();

  const onSubmit = values => generatePassword();

  

  return (
    <Dialog open={control.openGeneratePasswordDialog} onClose={()=>openGeneratePasswordDialog(false)} aria-labelledby="form-dialog-title">
    <DialogTitle id="form-dialog-title">Reset password</DialogTitle>
      <form onSubmit={handleSubmit(onSubmit)}>
        <DialogContent>
            <DialogContentText>
                Are you sure you want to reset the password for the user? This action will send a new password to the user via email and cannot be reverted.
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={()=>openGeneratePasswordDialog(false)} color="secondary">
                Cancel
            </Button>
            <Button color="primary" type="submit">
                Continue
            </Button>
        </DialogActions>
      </form>
    </Dialog>

  )
}

const mapStateToProps = state => {
    return {
        control: state.control
    }
}

const mapDispatchToProps = dispatch => {
    return {
        openGeneratePasswordDialog: (values) => dispatch(openGeneratePasswordDialog(values)),
        generatePassword: (values) => dispatch(generatePassword(values))
    }
}

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GeneratePasswordDialogForm)